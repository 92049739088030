html {
  overflow: initial;
}
.cdk-global-scrollblock, body, .cdk-global-overlay-wrapper {
  //overflow-y: auto;
}

.mat-checkbox {
  .mat-checkbox-frame {
    border-color: color($c-black, 26);
    border-radius: 2px;
    border-width: 1px;
  }

  .mat-checkbox-checkmark {
    fill: color($c-primary, 100) !important;
  }

  .mat-checkbox-checkmark-path {
    stroke: color($c-primary, 100) !important;
  }

  .mat-checkbox-mixedmark {
    background-color: color($c-primary, 100);
  }

  &.mat-checkbox-checked, &.mat-checkbox-indeterminate {
    .mat-checkbox-frame {
      border-color: color($c-black, 26);
      border-radius: 2px;
    }

    .mat-checkbox-background {
      background-color: transparent;
    }
  }
}


// DO NO DELETE !!!!!
//@mixin mat-checkbox-theme($theme) {
//  $is-dark-theme: map-get($theme, is-dark);
//  $primary: map-get($theme, primary);
//  $accent: map-get($theme, accent);
//  $warn: map-get($theme, warn);
//  $background: map-get($theme, background);
//
//
//  // The color of the checkbox border.
//  $checkbox-border-color: if($is-dark-theme, rgba(white, 0.7), rgba(black, 0.54)) !default;
//
//  // The color of the checkbox's checkmark / mixedmark.
//  $checkbox-mark-color: mat-color($background, background);
//
//  // NOTE(traviskaufman): While the spec calls for translucent blacks/whites for disabled colors,
//  // this does not work well with elements layered on top of one another. To get around this we
//  // blend the colors together based on the base color and the theme background.
//  $white-30pct-opacity-on-dark: #686868;
//  $black-26pct-opacity-on-light: #b0b0b0;
//  $disabled-color: if($is-dark-theme, $white-30pct-opacity-on-dark, $black-26pct-opacity-on-light);
//
//  .mat-checkbox-frame {
//    border-color: $checkbox-border-color;
//  }
//
//  .mat-checkbox-checkmark {
//    fill: $checkbox-mark-color;
//  }
//
//  .mat-checkbox-checkmark-path {
//    // !important is needed here because a stroke must be set as an attribute on the SVG in order
//    // for line animation to work properly.
//    stroke: $checkbox-mark-color !important;
//  }
//
//  .mat-checkbox-mixedmark {
//    background-color: $checkbox-mark-color;
//  }
//
//  .mat-checkbox-indeterminate, .mat-checkbox-checked {
//    &.mat-primary .mat-checkbox-background {
//      background-color: mat-color($primary, 500);
//    }
//
//    &.mat-accent .mat-checkbox-background {
//      background-color: mat-color($accent, 500);
//    }
//
//    &.mat-warn .mat-checkbox-background {
//      background-color: mat-color($warn, 500);
//    }
//  }
//
//  .mat-checkbox-disabled {
//    &.mat-checkbox-checked, &.mat-checkbox-indeterminate {
//      .mat-checkbox-background {
//        background-color: $disabled-color;
//      }
//    }
//
//    &:not(.mat-checkbox-checked) {
//      .mat-checkbox-frame {
//        border-color: $disabled-color;
//      }
//    }
//  }
//
//  .mat-checkbox:not(.mat-checkbox-disabled) {
//    &.mat-primary .mat-checkbox-ripple .mat-ripple-element {
//      background-color: mat-color($primary, 0.26);
//    }
//
//    &.mat-accent .mat-checkbox-ripple .mat-ripple-element {
//      background-color: mat-color($accent, 0.26);
//    }
//
//    &.mat-warn .mat-checkbox-ripple .mat-ripple-element {
//      background-color: mat-color($warn, 0.26);
//    }
//  }
//}
// DO NOT DELETE !!
