html,
body {
  height: 100%;
}

html {
  font-size: inherit;
  line-height: inherit;
}

body {
  font-size: inherit;
  background-color: color($c-black, 10);
  color: color($c-secondary, 100);
  font-family: $f-secondary;
  font-weight: 400;
  margin: 0;
  padding: 0;

}

a, a:hover, a:focus {
  color: color($c-primary, 100);
  outline: 0;
  text-decoration: none;
}

a, div {
  img {
    display: block;
  }
}

div > p:first-child {
  margin-top: 0;
}

b, strong {
  font-weight: 600;
}

a, div, span, p, input, select, button, textarea,
header, footer, aside, section, main, article,
h1, h2, h3, h4, h5, h6 {
  @include box-sizing(border-box);
}

select, select option {
  font-family: 'Arial', sans-serif;
}

h1 {
  @include rem(font-size, 28px);
  font-family: $f-primary;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
  margin: 0;

  @include breakpoint(medium up) {
    @include rem(font-size, 30px);
  }

  @include breakpoint(large up) {
    @include rem(font-size, 34px);
  }

  @include breakpoint(xxlarge up) {
    @include rem(font-size, 40px);
  }
}

h2 {
  @include rem(font-size, 24px);
  font-weight: 600;
}

.hidden {
  display: none !important;
}

.main-container {
  @include rem(margin, 0 auto);
  @include rem(padding, $spacing-large $spacing-medium);
  display: block;

  @include breakpoint(medium up) {
    @include rem(padding, $spacing-xlarge 0);
    //@include rem(padding, $spacing-xlarge $spacing-large);
    max-width: 1070px;
  }

  @include breakpoint(small down) {
    @include rem(padding, 0);
  }
}

.header-container {
  @include rem(margin-bottom, $spacing-medium);
}

.box-container {
  @include rem(padding, $spacing-small);
  @include rem(margin, 0 auto);
  display: block;
  background-color: color($c-white, 100);

  & + .box-container {
    @include rem(margin-top, $spacing-large);
  }

  h1:first-of-type {
    @include rem(font-size, 20px);
    margin-top: 0;
  }

  @include breakpoint(medium up) {
    @include rem(padding, $spacing-medium);

    & + .box-container {
      @include rem(margin-top, $spacing-xlarge);
    }
  }

  @include breakpoint(large up) {
    @include rem(padding, $spacing-large);
  }
}

.clearfix {
  @include clearfix;
}

.is-loading {
  position: relative;
  min-width: 150px;
  min-height: 150px;
  display: block;

  &::after {
    @include opacity(.8);
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    background-color: color($c-white, 100);
  }

  &::before {
    @include animation(loaderSpin 1s infinite);
    @include opacity(0.8);
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -40px;
    margin-left: -40px;
    background-image: url(/assets/img/loader-rolling-dark.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 80px;
    height: 80px;
    z-index: 2;
  }
}

.is-loading-overlay {
  position: relative;
  min-width: 150px;
  min-height: 150px;
  display: block;

  &::after {
    @include opacity(1);
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    background-color: color($c-secondary, 95);
  }

  &::before {
    @include opacity(1);
    @include animation(loaderSpin 1s infinite);
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -40px;
    margin-left: -40px;
    background-image: url(/assets/img/loader-rolling-dark.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 80px;
    height: 80px;
    z-index: 11;
  }
}

.blurContainer {
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
}

.sv-component {
  width: 100%;
  height: 100%;
}

.router-outlet-container {
  width: 100%;
}

.router-outlet-container-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
}

.green {
  color: color($c-green, 100);
}

.red {
  color: color($c-red, 100);
}

.hidden {
  visibility: hidden;
  height: 0 !important;
  width: 0 !important;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.info-msg-banner {
  @include rem(font-size, 14px);
  @include rem(margin, 5px 0);
  @include rem(padding, 5px 7px);
  color: color($c-white, 100);
  background-color: color($c-primary, 100);
  display: inline-block;
}
