.legal-form {
  @include flex-grid-row();

  &__invisible {
    display: none;
  }

  &__column {
    @include flex-grid-column(6, 0);
  }

  &__header {
    @include rem(padding, 0 $spacing-medium 0 ($spacing-medium - 5px));
    @include rem(margin, 0 0 $spacing-medium 0);
    border-left: 5px solid color($c-primary, 100);
  }

  &__form,
  &__aside {
    @include border-radius(3px);
    @include box-shadow(0 5px 20px rgba(0, 0, 0, .2));
    background-color: color($c-white, 100);
  }

  &__form {
    @include rem(padding, 70px 0 $spacing-medium 0);
  }

  &__aside {
    height: 100%;
    @include rem(padding, $spacing-medium);
    @include rem(margin-left, $spacing-small);
    background-color: color($c-secondary, 75);
    color: color($c-black, 26);

    .term {
      color: color($c-primary, 110);
    }

    .definition {
      @include rem(padding, 16px 20px);
      @include border-radius(6px);
      background-color: color($c-secondary, 96);
      color: color($c-black, 8);
    }

    .icon-lock {
      vertical-align: bottom;
      color: color($c-primary, 110);
    }

    &--focused {
      .term,
      .definition {
        @include filter(blur, 1px);
      }

      .focused {
        @include filter(blur, 0);
      }
    }
  }

  &__invisible {
    display: none;
  }

  &__main-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .form-groups {

    &--inline {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      @include rem(padding, 0 $spacing-medium);

      .form-group {
        flex: 1;
        padding: 0;

        & + .form-group {
          max-width: 40%;
          @include rem(margin-left, $spacing-small);
        }
      }
    }
  }

  .form-group {
    @include rem(padding, 0 $spacing-medium);

    > label {
      display: block;
      line-height: normal;
    }
  }

  .form-control-wrap {
    position: relative;

    & + .form-control-wrap {
      @include rem(margin-top, $spacing-xsmall);
    }
  }

  .form-control-lock {
    position: absolute;
    right: 15px;
    top: 10px;
    color: color($c-black, 50);
  }

  .mat-datepicker-toggle {
    @include rem(top, -38px);
    @include rem(right, 5px);
    position: absolute;
  }

  &--disabled {
    .form-control,
    .form-control-placeholder,
    .mat-input-element {
      @include rem(padding-right, 55px);
      @include opacity(0.5);
    }

    .form-control-select::before,
    .form-control-text .mat-icon-button {
      display: none;
    }
  }

  .requiredInput {
    color: color($c-red, 100)
  }
}

.showHideLegalFormZone {
  position: relative;

  .fieldsetContainer {
    height: 0;
    overflow: hidden;
    -webkit-transition: height 50ms ease-in-out;
    -moz-transition: height 50ms ease-in-out;
    -o-transition: height 50ms ease-in-out;
    transition: height 50ms ease-in-out;
  }

  #toggleCommonDetails,
  #toggleLegalDocuments,
  #toggleLegalRepresentative,
  #toggleLegalBanking,
  #toggleShareholders {
    display: none;

    .expand {
      display: flex;
    }
  }

  #toggleCommonDetails:checked,
  #toggleLegalDocuments:checked,
  #toggleLegalRepresentative:checked,
  #toggleLegalBanking:checked,
  #toggleShareholders:checked {
    & ~ .fieldsetContainer {
      height: 100%;
      overflow: visible;
    }

    & ~ label .arrow-dn {
      display: none;
    }

    & ~ label .arrow-up {
      display: inline-block;
    }
  }

  .toggle {
    @include flex-grid-row();
  }

  .toggleLabel {
    display: flex;
    cursor: pointer;
    position: relative;
    margin-bottom: -60px;
    width: 100%;
    @include rem(margin-top, $spacing-small);
    @include flex-grid-column(6, 0);
    @include border-radius(3px);
    background-color: color($c-white, 100);
    flex-flow: row;
    z-index: 3;

    .orangeBorder {
      height: 23px;
      border-left: 5px solid color($c-primary, 100);
      @include rem(margin-top, 17px);
    }

    .incompleteNotice,
    .completeNotice {
      display: flex;
      align-self: center;
      margin-left: auto;
      line-height: 2.25;
      @include rem(margin-right, $spacing-xsmall);

      span {
        @include rem(padding, 7px 3px 0);

        i {
          @include rem(font-size, 30px);
        }
      }
    }

    .incompleteNotice {
      color: color($c-red, 100);

      .attachment-item__icon {
        position: relative;
        color: color($c-red, 100);
      }
    }

    .completeNotice {
      color: color($c-green, 110);

      .attachment-item__icon {
        color: color($c-green, 110);
      }
    }

    span {
      @include rem(padding, 13px 3px 0);

      i {
        @include rem(font-size, 35px);
      }
    }
  }

  label .arrow-dn {
    display: inline-block;
    margin-top: 4px;
  }

  label .arrow-up {
    margin-top: 4px;
    display: none;
  }

  .attachment-item__icon {
    color: color($c-primary, 110);
    vertical-align: middle;
    width: 24px;
    height: 24px;
  }
}
