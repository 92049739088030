.site-branding-block {

  a {
    display: inline-block;
  }

  img {
    width: auto;
    height: 50px;
    padding: 15px;
  }

  @include breakpoint(medium up) {
    img {
      height: 90px;
      padding: 10px 15px;
    }
  }

  a > img {
    @include rem(padding, 30px 20px 10px 10px);
    height: 75px;
    box-sizing: border-box;
  }
}

.page-header-container {
  .site-branding-block {
    .large {
      display: block;
    }

    .small {
      display: none;
    }
  }
}

.page-header-container {

  &.is-sticky {
    .site-branding-block {
      img {
        @include rem(padding, 12px);
        height: 64px;
      }

      .large {
        display: none;
      }

      .small {
        display: block;
      }
    }
  }
}

.page-sidebar-container {
  .site-branding-block {

    background-color: color($c-secondary, 100);
    margin-left: 0 !important;
    text-align: center;
    line-height: 0;
    height: 90px;

    .large {
      display: block;
    }

    .small {
      display: none;
    }
  }

  &.collapsed {
    .site-branding-block {
      background-color: color($c-secondary, 95);
      margin-left: 0 !important;
      text-align: center;
      line-height: 0;

      img {
        padding: 0;
        height: 35px;
        margin-top: 37px;
      }

      .large {
        display: none;
      }

      .small {
        display: block;
      }
    }
  }
}
