.app-page {
  .quiz-description {
    img {
      max-width: 100%;
    }
  }
  max-width: 95%;

  .btn-filter {
    @include rem(font-size, 13px);
    @include rem(padding, 8px 38px 8px 10px);
    @include rem(margin, 5px $spacing-xsmall $spacing-xsmall 0);
    font-family: $f-secondary;
    background-color: color($c-black, 5);
    border: 1px solid color($c-black, 15);
    text-align: left;
    line-height: normal;
    display: inline-block;
    width: initial;
    text-transform: initial;
    font-weight: 300;
    position: relative;

    .mat-icon {
      @include rem(font-size, 13px);
      @include rem(padding, 8px);
      color: color($c-red, 100);
      border-left: 1px solid color($c-black, 15);
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
      bottom: 0;
      line-height: 1.5;
    }

    &:hover {
      @include box-shadow(0 2px 10px rgba(0, 0, 0, .07));
      color: color($c-red, 100);
    }
  }

  .box-container {
    @include box-shadow(0 5px 20px rgba(0, 0, 0, .1));
    @include rem(padding, $spacing-medium);
    background-color: color($c-white, 100);
    box-sizing: border-box;
  }

  .content-wrapper {
    @include box-shadow(0 5px 20px rgba(0, 0, 0, .1));
    @include rem(padding, $spacing-medium);
    background-color: color($c-white, 100);
  }
}

.datatable {
  box-sizing: border-box;

  tbody {
    background: color($c-white, 100);
  }

  &.spaced-rows .ui-datatable {
    table {
      border-collapse: separate;
      border-spacing: 0 5px;
    }

    .ui-datatable-thead {
      border-bottom: none;
    }

    .ui-datatable-data {
      > tr {
        @include box-shadow(0 2px 10px rgba(0, 0, 0, 0.1));
        @include border-radius(3px);

        &:hover {
          td {
            @include box-shadow(0 20px 80px -20px rgba(0, 0, 0, 0.1));
          }
        }

        > td {
          @include transition(box-shadow .3s ease-in-out);
        }

        &.ui-datatable-odd {
          background-color: color($c-white, 100);
        }

        &.selected {
          @include box-shadow(0 1px 5px rgba(0, 0, 0, 0.1));

          > td {
            border-top: 2px solid color($c-primary, 60);
            border-bottom: 2px solid color($c-primary, 60);

            &:first-child {
              border-left: 2px solid color($c-primary, 60);
            }

            &:last-child {
              border-right: 2px solid color($c-primary, 60);
            }
          }

          p-dtcheckbox {
            .ui-chkbox-box {
              border: 1px solid color($c-secondary, 10);
              pointer-events: none;

              span {
                &:before {
                  @include rem(font-size, 10px);
                  color: color($c-secondary, 10);
                  line-height: 1.5;
                  content: "\F00C";
                  display: block;
                  font-family: FontAwesome;
                }
              }
            }
          }
        }

        &.ui-state-highlight {
          @include box-shadow(0 3px 15px rgba(0, 0, 0, 0.3));

          > td {
            border-top: 2px solid color($c-primary, 100);
            border-bottom: 2px solid color($c-primary, 100);

            &:first-child {
              border-left: 2px solid color($c-primary, 100);
            }

            &:last-child {
              border-right: 2px solid color($c-primary, 100);
            }
          }
        }
      }
    }
  }

  .ui-datatable {
    .ui-datatable-thead {
      border-bottom: 1px solid color($c-black, 15);

      > tr > th {
        @include rem(padding, $spacing-small $spacing-medium);
        background: none;

        .ui-column-title {
          @include rem(font-size, 13px);
          text-transform: uppercase;
          font-weight: 600;
        }
      }
    }

    .ui-datatable-data {
      > tr {
        background-color: color($c-white, 100);

        &.ui-datatable-odd {
          background-color: color($c-black, 3);
        }

        &.ui-row-separator {
          @include box-shadow(none);
          background: none;

          td {
            @include rem(padding, 10px);
          }
        }

        &.ui-row-editing {
          @include box-shadow(none);
          @include rem(margin-top, $spacing-large);
          border: 1px dashed color($c-primary, 100);
          background: color($c-white, 100);
          display: flex;
          align-items: flex-start;
          justify-content: center;
          position: relative;

          &:hover {
            td {
              box-shadow: none;
            }
          }

          .form-control-error {
            position: absolute;
          }

          td {
            &.ui-cell-editing {
              @include rem(padding, $spacing-medium $spacing-xsmall/2);
              flex: 0 1 250px;
              display: flex !important;

              .ui-cell-editor {
                width: 100%;
              }
            }

            &.ui-selection-column {
              display: none !important;
            }

            &.options {
              text-align: left;
              flex: 0 0 auto;

              .btn-add, .btn-remove {
                margin: 0;
                display: inline-block;

                .mat-icon {
                  @include rem(font-size, 16px);
                  @include rem(margin, 0 0 0 7px);
                }
              }

              .btn-add {
                color: color($c-secondary, 100);
                display: inline-block;
                width: auto;
              }

              .btn-remove {
                @include border-radius(100%);
                @include box-shadow(0 0 10px rgba(0, 0, 0, 0.2));
                @include rem(height, 26px);
                @include rem(width, 26px);
                border: 0;
                background-color: color($c-red, 100);
                color: color($c-white, 100);
                position: absolute;
                top: -13px;
                right: -13px;
                padding: 0;
                outline: 0;

                .mat-icon {
                  @include rem(font-size, 15px);
                  @include rem(line-height, 21px);
                  margin: 0;
                  font-weight: 600;
                  vertical-align: middle;
                }
              }
            }
          }

          .btn {
            @include rem(padding, 12px 17px);
          }

          .form-group {
            margin: 0;

            .form-control {
              @include rem(padding, 12px);
            }

            &.absolute-label {
              .form-control.ng-dirty + label {
                background-color: color($c-white, 100);
              }
            }
          }
        }
      }

      > tr > td {
        .value {
          @include rem(margin-bottom, 5px);
          font-weight: 600;
        }

        .meta-item {
          @include rem(font-size, 12px);
          color: color($c-black, 50);

          .mat-icon {
            @include rem(font-size, 16px);
            vertical-align: text-top;
          }

          span {
            display: inline-block;
          }

          a, a:hover, a:visited {
            display: inline-block;
            color: color($c-black, 50);
            cursor: pointer;

            .mat-icon {
              @include rem(margin-right, 3px);
            }
          }
        }

        a.meta-item {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      > tr > td,
      > tr > td.ui-editable-column {
        @include rem(padding, $spacing-medium);
        @include rem(font-size, 14px);

        .form-group {
          margin: 0;
        }

        .form-control-select {
          select {
            @include rem(padding, 0 41px 0 10px);
            height: 31px;
          }

          &::before {
            line-height: 31px;
            height: 31px;
            width: 31px;
          }
        }
      }
    }
  }

}

.app-page--title {
  @include rem(margin, 0 $spacing-large $spacing-xsmall $spacing-xsmall);
  @include rem(font-size, 30px);
  text-align: left;
  text-transform: none;
  display: inline-block;

  span {
    color: color($c-secondary, 25);
    font-weight: 300;
  }
}

.app-page--subtitle {
  @include rem(margin, 0 0 $spacing-small);
  @include rem(font-size, 18px);
  text-align: left;

  .btn {
    @include rem(padding, 6px 10px);
    @include rem(font-size, 12px);
    float: right;
    display: inline-block;
    width: auto;

    .mat-icon {
      @include rem(font-size, 14px);
    }
  }
}

.app-page--header {
  @include clearfix;
  @include rem(padding-bottom, $spacing-xsmall);
  margin-top: 0;
  text-align: left;
  border-bottom: 1px solid color($c-black, 15);
}

.form-details {
  @include rem(padding-top, 5px);
}

.form-details + .form-details {
  @include rem(margin-top, $spacing-xlarge);

  &::before {
    content: '';
    display: block;
    width: 100px;
    border-top: 1px solid color($c-black, 25);
    position: relative;
    left: $spacing-medium;
    top: (0-$spacing-xlarge/2 - 5px);
  }
}

.form-details--content {
  @include rem(font-size, 14px);
  @include rem(padding, 0 $spacing-medium);
}

.form-details--title {
  @include rem(padding, 0 $spacing-medium 0 ($spacing-medium - 5px));
  @include rem(margin, 0 0 $spacing-medium 0);
  @include rem(font-size, 14px);
  text-transform: uppercase;
  border-left: 5px solid color($c-primary, 100);
}

.form-details--item {
  line-height: 1;
}

.form-details--item-label {
  @include rem(padding-right, $spacing-medium);
  white-space: nowrap;
  width: 1%;
  vertical-align: top;
}

.form-details--item-value {
  color: color($c-black, 50);
  word-break: break-all;
  vertical-align: top;
}


@include breakpoint(medium up) {
  .datatable {
    height: auto;
  }

  .app-page.main-container .row {
    max-width: initial !important;
  }
}
