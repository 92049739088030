
.ui-datatable {
  .ui-datatable-odd {
    background-color: color($c-black, 10);
  }

  .ui-chkbox-box.ui-widget {
    @include border-radius(3px);
    width: 18px;
    height: 18px;
    border: 1px solid color($c-black, 25);
    background: color($c-white, 100);

    &:focus {
      @include box-shadow(0 0 8px color($c-primary, 100, .9));
      outline: 0;
    }

    .ui-chkbox-icon.fa-check {
      @include rem(font-size, 10px);
      color: color($c-primary, 100);
      line-height: 1.5;
    }
  }

  .ui-datatable-thead > tr > th {
    @include rem(padding, $spacing-xsmall $spacing-medium);
    //background-color: color($c-primary, 100);
    border: 0;
    text-align: left;

    &.options {
      width: 120px;
    }
  }

  .ui-datatable-data {
    > tr > td,
    > tr > td.ui-editable-column,
    > tr > td.ui-editable-column.ui-cell-editing {
      @include rem(padding, $spacing-xsmall $spacing-medium);
      border: 0;

      .ui-column-title {
        min-width: 80px;
      }

      &.options {
        text-align: right;

        .btn-add, .btn-remove {
          @include rem(margin-top, 5px);
          display: inline-block;
          cursor: pointer;

          .mat-icon {
            @include rem(font-size, 28px);
          }
        }

        .btn-add {
          color: color($c-green, 100);
        }

        .btn-remove {
          @include rem(margin-left, $spacing-small);
          color: color($c-red, 100);
        }
      }

      &.ui-selection-column {

        &.ui-cell-editing {
          .ui-chkbox.ui-widget {
            display: none
          }
        ;
        }

        .ui-column-title {
          display: none;
        }
      }

      &:first-child {
        @include rem(padding-top, $spacing-medium);
      }

      &:last-child {
        @include rem(padding-bottom, $spacing-medium);
      }
    }

    .form-control {
      @include rem(padding, 7px 10px);
    }
  }

  .ui-paginator-element {
    @include rem(padding, 7px 10px);


    &.ui-state-disabled {
      @include opacity(.5);
    }

    &.ui-paginator-page.ui-state-active {
      border: 1px solid color($c-primary, 100);
    }

    .fa-backward::before {
      //content: $fa-var-chevron-left;
    }

    .fa-forward:before {
      //content: $fa-var-chevron-right;
    }
  }

  .ui-paginator-first, .ui-paginator-last {
    display: none;
  }

  &.ui-datatable-stacked {
    .ui-datatable-data.ui-widget-content {
      border: 1px solid color($c-black, 15);
    }
  }
}

@include breakpoint(medium up) {
  .ui-datatable {
    .ui-datatable-header {
      @include rem(margin-bottom, $spacing-medium);
      padding: 0;
    }

    .ui-datatable-thead th {
      &.ui-selection-column {
        width: 50px;
        vertical-align: top;

        .ui-chkbox-box.ui-widget {
          @include rem(margin-top, 3px);
        }
      }
    }

    .ui-datatable-data {
      > tr > td {
        vertical-align: top;

        &.ui-selection-column {
          .ui-chkbox-box.ui-widget {
            @include rem(margin-top, 3px);
          }
        }

        &:first-child {
          @include rem(padding-top, $spacing-xsmall);
        }

        &:last-child {
          @include rem(padding-bottom, $spacing-xsmall);
        }
      }
    }

    .ui-paginator {
      @include rem(margin-top, $spacing-medium);
    }
  }
}
