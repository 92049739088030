.no-content {
  text-align: center;

  h1 {
    @include rem(margin-bottom, $spacing-medium);
  }

  .description {
    @include rem(margin-bottom, $spacing-large);
  }

  .btn {
    @include rem(padding, 15px 25px);
    width: auto;
    display: inline-block;
  }
}
