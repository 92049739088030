.page-hero {
  @include rem(padding, 130px $spacing-large $spacing-xxlarge $spacing-large);

  .page-hero-particles {
    height: 100%;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    right: 0;

    canvas {
      height: 100%;
      width: 100%;
    }
  }

  .page-hero-message {
    @include rem(margin, 0 auto);
    max-width: 680px;
    width: 100%;
    position: relative;
    text-align: center;
    color: color($c-white, 100);

    &::before {
      @include rem(margin, 0 auto 40px);
      content: '';
      width: 60px;
      height: 6px;
      display: block;
      background: color($c-primary, 100);
    }

    h1 {
      @include rem(margin, 0 0 28px);
    }

    p {
      @include rem(margin, 0 0 35px);
      @include rem(padding, 0 18px);
      @include rem(font-size, 16px);
      line-height: 2;
    }

    .btn {
      @include rem(padding, 16px);
      @include rem(font-size, 14px);
      display: block;
      max-width: 250px;
      width: 100%;
    }

    .form-actions .btn {
      @include rem(padding, 12px);
      max-width: inherit;
    }
  }

  .btn-scroll {
    background-image: url(/assets/img/btn-scroll.png);
    background-size: cover;
    width: 39px;
    height: 39px;
    cursor: pointer;
    display: none;
    position: absolute;
    left: calc(50% - 20px);

    &.white {
      background-image: url(/assets/img/btn-scroll-white.png);
    }
  }

  .form-container {
    @include background-size(cover);
    @include box-shadow(0 0 8px 2px color($c-black, 100, 0.1));
    @include border-radius(0);
    @include rem(padding, 25px);
    background-image: url(/assets/img/modal-bg.jpg);
    background-color: color($c-secondary, 10);
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    max-width: 400px;
    margin: auto;
  }

  .form-header {
    margin: 0 auto;
    text-align: center;
    width: 80%;

    h1 {
      @include rem(font-size, 28px);
      @include rem(margin, 10px 0 30px 0);
      color: color($c-secondary, 75);
      font-family: $f-primary;
      line-height: 1.25;
      text-transform: uppercase;
    }
  }

  @include breakpoint(medium up) {
    min-height: 655px;

    .page-hero-message {
      .btn {
        @include rem(padding, 18px);
      }
    }

    .btn-scroll {
      @include animation(fadeInDown 1.5s);
      display: block;
    }
  }

  @include breakpoint(large up) {
    .form-container {
      @include border-radius(6px);
    }
  }

  @include breakpoint(xxlarge up) {
    min-height: 755px;

    .page-hero-message {
      &::before {
        width: 90px;
        height: 8px;
      }
    }
  }

}
