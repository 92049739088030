.mktoButton,
.btn {
  @include border-radius(4px);
  @include transition(background 400ms ease-in-out, color 400ms ease-in-out, box-shadow 400ms ease-in-out, border 200ms ease-in-out);
  @include rem(font-size, 14px);
  @include rem(padding, 10px 17px);
  border: none;
  color: color($c-secondary, 4);
  line-height: 1.4;
  display: block;
  margin: 0 auto;
  outline: 0;
  background-color: color($c-primary, 100);
  font-family: $f-primary;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  height: initial;
  overflow: hidden;
  //text-overflow: ellipsis;
  width: 100%;

  &:hover,
  &:focus {
    color: color($c-secondary, 100);
    background-color: color($c-primary, 75);
    text-decoration: none;
  }

  &:disabled,
  &:disabled:hover {
    color: color($c-secondary, 50);
    background: color($c-primary, 50);
    cursor: default;

    &.inside-caret {
      span {
        background: color($c-primary, 25);
      }

      span .mat-icon {
        @include opacity(0.4);
      }
    }

  }

  &.ng-pending:disabled,
  &.ng-pending:disabled:hover {
    position: relative;
    color: transparent;

    &::before {
      @include transform(translate(-50%, -50%));
      @include opacity(0.5);
      background-color: transparent;
      background-image: url(/assets/img/loader-bars.svg);
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 30px;
      width: 30px;
    }
  }

  span {
    font-family: $f-primary;
    font-weight: 700;
  }

  .fa {
    @include rem(margin-right, 10px);
  }

  .mat-icon {
    //@include rem(margin-left, 7px);
    //@include rem(font-size, 16px);
    //vertical-align: text-bottom;
    //font-weight: 600;
    //position: relative;
    //top: 6px;
    vertical-align: middle;
    display: inline-block;
    align-self: center;
    font-weight: 600;
    height: 16px;
    width: 14px;
    font-size: 14px;
  }

  &.btn-feedback {
    @include border-radius(0);
    background-color: color($c-secondary, 75);
    color: color($c-primary, 100);
  }

  &.inside-caret {
    position: relative;
    padding-right: 52px;
    overflow: visible;

    span {
      @include border-right-radius(4px);
      @include rem(width, 35px);
      @include rem(height, 39px);
      @include rem(right, -1px);
      background: color($c-primary, 100);
      position: absolute;
      top: 0;
      text-align: center;

      .mat-icon {
        @include rem(font-size, 20px);
        @include rem(margin, 10px auto 0 auto);
        color: color($c-white, 100);
      }
    }
  }
  &.new-design {
    border-radius: 0;
  }
}

.input-group .input-group-button button {
  @extend .btn;
}

.btn-blue {
  color: color($c-white, 100);
  background-color: color($c-blue, 100);

  &:hover,
  &:focus {
    background-color: color($c-blue, 125);
  }

  &:disabled,
  &:disabled:hover {
    color: color($c-white, 100);
    background-color: color($c-blue, 50);
  }
}

.btn-secondary {
  color: color($c-white, 100);
  background-color: color($c-secondary, 75);

  &:hover,
  &:focus {
    color: color($c-white, 100);
    background-color: color($c-secondary, 100);
  }

  &:disabled,
  &:disabled:hover {
    color: color($c-white, 100);
    background-color: color($c-secondary, 25);
  }
}

.btn-danger {
  color: color($c-white, 100);
  background-color: color($c-red, 100);

  &:hover,
  &:focus {
    color: color($c-white, 100);
    background-color: color($c-red, 125);
  }

  &:disabled,
  &:disabled:hover {
    color: color($c-white, 100);
    background-color: color($c-red, 50);
  }
}


.social-share-buttons {
  a {
    @include rem(padding, 10px);
    font-size: 0;
    font-family: $f-secondary;
    color: color($c-white, 100);
    display: inline-block;
    min-width: 45px;
    text-align: center;
    cursor: pointer;

    &::before {
      //@include fa-icon;
      @include rem(font-size, 18px);
    }

    & + a {
      @include rem(margin-left, 10px);
    }
  }

  .facebook {
    background-color: color($c-socials, facebook);

    &::before {
      //content: $fa-var-facebook;
    }
  }

  .twitter {
    background-color: color($c-socials, twitter);

    &::before {
      //content: $fa-var-twitter;
    }
  }

  .google {
    background-color: color($c-socials, google);

    &::before {
      //content: $fa-var-google-plus;
    }
  }

  .linkedin {
    background-color: color($c-socials, linkedin);

    &::before {
      //content: $fa-var-linkedin;
    }
  }

  @include breakpoint(large up) {
    a {
      @include rem(padding, 5px 10px);
      @include rem(font-size, 12px);

      &::before {
        @include rem(font-size, 12px);
        @include rem(margin-right, 10px);
      }
    }
  }
}

.btn-more {
  @include rem(font-size, 14px);
  @include rem(padding, 10px 17px);
  @include border-radius(4px);
  @include transition(border 400ms ease-in-out);
  @include rem(margin, $spacing-large auto 0 auto);
  display: block;
  color: color($c-secondary, 100);
  width: 100%;
  border: 2px solid color($c-primary, 100);
  text-align: center;
  font-family: $f-primary;
  text-transform: uppercase;
  font-weight: 700;

  &:hover, &:focus {
    border-color: color($c-primary, 100);
    color: color($c-secondary, 100);
    text-decoration: none;
  }
}

.btn-switch {
  overflow: initial;
  width: 50px !important;
  height: 30px;

  &.ui-inputswitch-checked {
    .ui-inputswitch-on {
      background-color: color($c-primary, 100);
    }
  }

  .ui-inputswitch-on {
    @include rem(border-radius, 25px);
    @include box-shadow(inset 0 1px 1px color($c-black, 100, 0.3));
    @include transition(background-color 0.2s ease-out);
    position: relative;
    top: 3px;
    line-height: 25px;
    height: 25px;
    width: 100% !important;
    background-color: color($c-black, 30);

    span {
      content: '';
      display: none;
    }
  }

  .ui-inputswitch-off {
    display: none !important;
  }

  .ui-inputswitch-handle {
    @include border-radius(100%);
    @include transition(left 0.1s ease-out);
    width: 30px !important;
    height: 30px !important;
    border: 1px solid color($c-black, 30);
    background-color: color($c-black, 10);
  }
}

.btn-hollow {
  @include rem(padding, 8px 12px);
  background: none;
  border: 2px solid color($color-blue, 80);
  color: color($color-blue, 80);

  &:hover,
  &:focus {
    color: color($c-white, 100);
    background-color: color($color-blue, 80)!important;
    border-color: color($color-blue, 80);
    background: none;
  }

  &:disabled,
  &:disabled:hover {
    background: none;
    color: color($color-blue, 50);
    border-color: color($color-blue, 50);
  }
}

