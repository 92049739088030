
.mat-slide-toggle {
  $toggle-size: 12px;

  .mat-slide-toggle-bar {
    background-color: color($c-white, 100);
    border: solid 1px color($c-primary, 100);
  }

  .mat-slide-toggle-thumb {
    background-color: color($c-white, 100);
  }

  &.mat-checked {
    &:not(.mat-disabled) {
      .mat-slide-toggle-bar {
        background-color: color($c-primary, 100);
      }

      .mat-slide-toggle-thumb {
        background-color: color($c-white, 100);
      }
    }

    .mat-slide-toggle-thumb-container {
      left: 5px;
    }
  }

  .mat-slide-toggle-bar {
    height: 18px;
  }

  .mat-slide-toggle-thumb-container {
    height: $toggle-size;
    width: $toggle-size;
    top: 3px;
    left: 3px;
    cursor: pointer;
  }

  .mat-slide-toggle-thumb {
    background-color: color($c-primary, 100);
    position: relative;
    top: -1px;
    height: $toggle-size;
    width: $toggle-size;
  }

  .mat-slide-toggle-ripple.mat-ripple {
    display: none;
  }
}
