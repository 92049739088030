.loader-application {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: color($c-secondary, 100);

  &::before {
    @include animation(loaderSpin 1s infinite);
    @include opacity(0.8);
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -40px;
    margin-left: -40px;
    background-image: url(/assets/img/loader-rolling.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 80px;
    height: 80px;
  }

}
