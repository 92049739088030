$maxLevel: 4;

.progress-bar {
  display: block;
  background-color: color($c-secondary, 100);
  height: 5px;
  width: 100%;
  position: relative;

  span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: color($c-primary, 100);
    display: inline-block;
  }
}
