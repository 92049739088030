.rating {

  .material-icons {
    @include rem(font-size, 20px);
    @include rem(margin-right, 2px);
    color: color($c-primary, 100);
    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }

    &.half {
      position: relative;

      &:after {
        //@include fa-icon;
        @include transform(scaleX(-1));
        //content: $fa-var-star-half;
        color: color($c-secondary, 75);
        position: absolute;
        right: 1px;
      }
    }

    &.empty {
      color: color($c-secondary, 75);
    }
  }

  &.readonly {
    @include rem(font-size, 16px);

    i.fa {
      @include rem(margin-right, 5px);
      cursor: default;
    }
  }

  @include breakpoint(medium up) {
    i.fa {
      @include rem(font-size, 16px);
      @include rem(padding-right, 5px);
      margin-right: 0;

      &:last-of-type {
        padding-right: 0;
      }
    }

    &.readonly {
      i.fa {
        margin-right: 0;
      }
    }
  }

}
