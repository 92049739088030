$rem-baseline: 16px !default;
$rem-fallback: false !default;
$rem-px-only: false !default;

@function rem-separator($list, $separator: false) {
  @if $separator == "comma" or $separator == "space" {
    @return append($list, null, $separator);
  }

  @if function-exists("list-separator") == true {
    @return list-separator($list);
  }

  // list-separator polyfill by Hugo Giraudel (https://sass-compatibility.github.io/#list_separator_function)
  $test-list: ();
  @each $item in $list {
    $test-list: append($test-list, $item, space);
  }

  @return if($test-list == $list, space, comma);
}

@mixin rem-baseline($zoom: 100%) {
  font-size: $zoom / 16px * $rem-baseline;
}

@function rem-convert($to, $values...) {
  $result: ();
  $separator: rem-separator($values);

  @each $value in $values {
    @if type-of($value) == "number" and unit($value) == "rem" and $to == "px" {
      $result: append($result, $value / 1rem * $rem-baseline, $separator);
    } @else if type-of($value) == "number" and unit($value) == "px" and $to == "rem" {
      $result: append($result, $value / $rem-baseline * 1rem, $separator);
    } @else if type-of($value) == "list" {
      $value-separator: rem-separator($value);
      $value: rem-convert($to, $value...);
      $value: rem-separator($value, $value-separator);
      $result: append($result, $value, $separator);
    } @else {
      $result: append($result, $value, $separator);
    }
  }

  @return if(length($result) == 1, nth($result, 1), $result);
}

@function rem($values...) {
  @if $rem-px-only {
    @return rem-convert(px, $values...);
  } @else {
    @return rem-convert(rem, $values...);
  }
}

@mixin rem($properties, $values...) {
  @if type-of($properties) == "map" {
    @each $property in map-keys($properties) {
      @include rem($property, map-get($properties, $property));
    }
  } @else {
    @each $property in $properties {
      @if $rem-fallback or $rem-px-only {
        #{$property}: rem-convert(px, $values...);
      }
      @if not $rem-px-only {
        #{$property}: rem-convert(rem, $values...);
      }
    }
  }
}

//@mixin rem($property, $values) {
//  $px : ();
//  $rem: ();
//
//  @each $value in $values {
//
//    @if $value == 0 or $value == auto {
//      $px : append($px , $value);
//      $rem: append($rem, $value);
//    }
//
//    @else {
//      $unit: unit($value);
//      $val: parseInt($value);
//
//      @if $unit == "px" {
//        $px : append($px,  $value);
//        $rem: append($rem, ($val / 16 + rem));
//      }
//
//      @if $unit == "rem" {
//        $px : append($px,  ($val * 16 + px));
//        $rem: append($rem, $value);
//      }
//    }
//  }
//
//  @if $px == $rem {
//    #{$property}: $px;
//  } @else {
//    #{$property}: $px;
//    #{$property}: $rem;
//  }
//}

@mixin material-icons {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Icons';
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

@mixin persistent-scrollbars($thumb-color: rgba(0, 0, 0, .4)) {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 6px;
    background: transparent;
  }

  &::-webkit-scrollbar:horizontal {
    height: 6px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, .5); /* should match background, can't be transparent */
    background-color: $thumb-color;
  }
}

@mixin persistent-menu-scrollbars() {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 6px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .4);
  }
}

@mixin mask($value) {
  -webkit-mask: $value;
  mask: $value;
}

@mixin svg-icon($path, $color) {
  @include mask(url($path) no-repeat 50% 50%);
  content: '';
  display: inline-block;
  background-color: $color;
  vertical-align: middle;
}

@mixin wrap-with-hyphens {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

@mixin opacity($value) {
  $IEValue: $value*100;
  opacity: $value;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
  filter: alpha(opacity=$IEValue);
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content
  }
  &:-moz-placeholder {
    @content
  }
  &::-moz-placeholder {
    @content
  }
  &:-ms-input-placeholder {
    @content
  }
}

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin word-wrap($value) {
  -ms-word-wrap: $value;
  word-wrap: $value;
}

@mixin user-select($value) {
  -webkit-user-select: $value; /* Chrome all / Safari all */
  -moz-user-select: $value; /* Firefox all */
  -ms-user-select: $value; /* IE 10+ */
  user-select: $value; /* Likely future */
}

/* Border Radius Mixin */
@mixin border-radius($value) {
  -webkit-border-radius: $value;
  -moz-border-radius: $value;
  border-radius: $value;
}

@mixin border-top-left-radius($radius) {
  border-top-left-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  -moz-border-top-left-radius: $radius;
}

@mixin border-top-right-radius($radius) {
  border-top-right-radius: $radius;
  -webkit-border-top-right-radius: $radius;
  -moz-border-top-right-radius: $radius;
}

@mixin border-bottom-left-radius($radius) {
  border-bottom-left-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  -moz-border-bottom-left-radius: $radius;
}

@mixin border-bottom-right-radius($radius) {
  border-bottom-right-radius: $radius;
  -webkit-border-bottom-right-radius: $radius;
  -moz-border-bottom-right-radius: $radius;
}

@mixin border-left-radius($radius) {
  @include border-top-left-radius($radius);
  @include border-bottom-left-radius($radius);
}

@mixin border-right-radius($radius) {
  @include border-top-right-radius($radius);
  @include border-bottom-right-radius($radius);
}

@mixin border-top-radius($radius) {
  @include border-top-left-radius($radius);
  @include border-top-right-radius($radius);
}

@mixin border-bottom-radius($radius) {
  @include border-bottom-left-radius($radius);
  @include border-bottom-right-radius($radius);
}

/* Backgroun Size Mixin */
@mixin background-size($value) {
  -webkit-background-size: $value;
  -moz-background-size: $value;
  -o-background-size: $value;
  background-size: $value;
}

/* Box Sizing Mixin */
@mixin box-sizing($value) {
  -webkit-box-sizing: $value;
  -moz-box-sizing: $value;
  box-sizing: $value;
}

/* Transition Mixin */
@mixin transition($value...) {
  -webkit-transition: $value;
  -moz-transition: $value;
  -o-transition: $value;
  transition: $value;
}

@mixin transform-style($value...) {
  -webkit-transform-style: $value;
  -moz-transform-style: $value;
  transform-style: $value;
}

@mixin transition-delay($value...) {
  -webkit-transition-delay: $value; /* Safari */
  transition-delay: $value;
}

/* Animation Mixin */
@mixin animation($value) {
  -webkit-animation: $value;
  -moz-animation: $value;
  -o-animation: $value;
  animation: $value;
}

/* Keyframes Mixin */
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @-o-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

/* Transform Mixin */
@mixin transform($value) {
  -webkit-transform: $value;
  -moz-transform: $value;
  -o-transform: $value;
  -ms-transform: $value;
  transform: $value;
}

@mixin box-shadow($value...) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  box-shadow: $value;
}

@mixin square($size) {
  @include rem(width, $size);
  @include rem(height, $size);
}

@mixin circle($diameter) {
  @include square($diameter);
  @include border-radius(100%);
}

@mixin filter($filter-type, $filter-amount) {
  -webkit-filter: $filter-type+unquote('(#{$filter-amount})');
  -moz-filter: $filter-type+unquote('(#{$filter-amount})');
  -ms-filter: $filter-type+unquote('(#{$filter-amount})');
  -o-filter: $filter-type+unquote('(#{$filter-amount})');
  filter: $filter-type+unquote('(#{$filter-amount})');
}
