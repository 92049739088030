.cms-page,
.cms-page--form {
  .main-info {
    .content h1,
    .page-title {
      @include rem(margin-bottom, $spacing-medium);
    }
  }

  @include breakpoint(medium up) {
    .main-info {
      .content h1,
      .page-title {
        @include rem(margin-bottom, $spacing-large);
        text-align: center;
      }
    }
  }
}

.cms-page--form {
  .page-container {
    .description {
      p:first-child {
        margin-top: 0;
      }
    }

    .image-container {
      @include rem(padding, $spacing-small);

      img {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
      }
    }

    .additional-info {
      > :first-child {
        @include rem(margin-top, $spacing-xxlarge);
      }

      > h2 {
        @include rem(font-size, 28px);
        @include rem(margin, $spacing-xxlarge auto $spacing-xlarge auto);
        line-height: 1.4;
        max-width: 630px;
        text-align: center;
        text-transform: uppercase;

        &::before {
          @include rem(margin-bottom, $spacing-medium);
          margin-right: auto;
          margin-left: auto;
          display: block;
          content: '';
          width: 60px;
          height: 6px;
          background-color: color($c-primary, 100);
        }

        @include breakpoint(medium up) {
          @include rem(font-size, 30px);
          @include rem(margin-bottom, $spacing-large);
        }

        @include breakpoint(large up) {
          @include rem(margin-bottom, 50px);
          @include rem(font-size, 36px);
          margin-left: auto;
          margin-right: auto;
          max-width: 730px;
        }
      }

      > .description {
        @include rem(margin, $spacing-large auto 0 auto);
        @include rem(font-size, 14px);
        text-align: justify;

        &::before {
          @include rem(margin, 0 auto $spacing-large auto);
          content: '';
          height: 1px;
          display: block;
          border-top: 1px solid color($c-secondary, 100);
        }
      }
    }

    @include breakpoint(medium up) {
      .main-info, .image-container, .additional-info {
        padding: 0;
      }

      .main-info {
        @include clearfix;
        text-align: justify;
      }

      .image-container {
        @include rem(padding, $spacing-medium);
        padding-top: 0;
        padding-right: 0;
        width: 220px;
        float: right;
      }

      .additional-info {
        > .description {
          width: 80%;
          text-align: center;

          &::before {
            width: 80%;
          }
        }
      }
    }

    @include breakpoint(large up) {
      .image-container {
        @include rem(padding, $spacing-large);
        padding-top: 0;
        padding-right: 0;
      }
    }
  }

  .contact-form-container {
    h3:first-of-type {
      @include rem(font-size, 20px);
      margin-top: 0;
    }

    @include breakpoint(large up) {
      .form-container {
        @include flex-grid-column(8, 0);
      }
    }
  }

}

.cms-page--hero-banner {
  @include rem(padding, $spacing-medium);
  height: 460px;
  width: 100%;
  background-size: cover;
  text-align: center;
  display: flex;
  align-items: center;
}

.cms-page--hero-banner-content {
  width: 100%;
}

.cms-page--hero-title {
  @include rem(font-size, 28px);
  font-family: $f-primary;
  color: color($c-white, 100);
  font-weight: 600;
  line-height: 1.4;
}

.cms-page--hero-subtitle {
  @include rem(margin-top, $spacing-xsmall);
  color: color($c-white, 100);
  line-height: 2;
}


@include breakpoint(medium up) {
  .cms-page--hero-title {
    @include rem(font-size, 32px);
  }
}

@include breakpoint(large up) {
  .cms-page--hero-title {
    @include rem(font-size, 36px);
  }
}

