.cdk-overlay-dark-backdrop {
  background: color($c-black, 100, 0.8);
}
.mat-dialog-container {
  @include persistent-scrollbars;
  padding: $spacing-large;
  position: relative;
  margin: 0 auto;

  .btn-close {
    @include rem(font-size, 16px);
    border: 0;
    background: none;
    position: absolute;
    right: 3px;
    top: 8px;
    color: color($c-red, 100);
    font-weight: bold;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}

.mat-dialog-panel {

  &--fluid {
    .mat-dialog-container {
      padding: 0 !important;
    }
  }
}
