.ui-accordion {
  .ui-accordion-tab {
    display: block;
    background-color: color($c-white, 100);

    & + .ui-accordion-tab {
      border-top: 1px solid darken(color($c-primary, 100), 15);
    }
  }

  .ui-accordion-header {
    background-color: color($c-primary, 100);
    margin-top: 0;

    a {
      color: color($c-black, 100);
      @include rem(padding, 12px 12px 12px 35px);
    }
  }

  @include breakpoint(medium up) {
    .ui-accordion-tab {
      & + .ui-accordion-tab {
        border-top: 0;
      }
    }

  }
}
