.sv-page {
  @include rem(padding, 30px);

  @include breakpoint(large down) {
    max-width: available;
    min-width: auto;
  }

  .sv-page-header {
    padding-bottom: $spacing-small;
    border-bottom: 1px solid color($c-black, 25);

    .sv-page-title {
      font-family: $f-primary;
      font-size: 26px;
      text-transform: capitalize;
      letter-spacing: -2px;

      .count {
        font-family: $f-primary;
        color: color($c-black, 25);
        font-weight: 200;
        letter-spacing: 0;
      }
    }

    @include flex-grid-row();
    max-width: initial;
    align-items: flex-start;

    .column {
      @include flex-grid-column(6, 0);
      @include rem(margin-right, -2px);
    }

    .column + .column {
      @include flex-grid-column(6);
      @include rem(padding-left, $spacing-medium);
      align-items: flex-end;
      display: flex;
    }

    .btn {
      width: auto;
      display: flex;
      margin-right: 0;

      .mat-icon {
        @include rem(margin-left, 7px);
        font-weight: 400;
        margin-right: 0;
      }
    }
  }
}

@include breakpoint(small down) {
  .sv-page {
    @include rem(padding, $spacing-xsmall);
  }
}
