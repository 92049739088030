
.project-card {
  position: relative;
  display: flex;
  flex-direction: column;

  min-width: 0;
  @include transition(box-shadow .3s ease);

  @include word-wrap(break-word);
  text-align: center;

  background-color: color($c-white, 100);
  cursor: pointer;

  &:hover {
    @include box-shadow(2px 4px 6px 0px color($c-black, 100, 0.2));
  }

  &__content {
    @include rem(padding, 20px);
  }

  &__name {
    display: inline-block;
    max-height: 1.75rem;
    max-width: 100%;
    @include rem(margin, 5px 0);

    @include text-truncate;
    line-height: 1.75rem;
    font-weight: bold;
    @include rem(font-size, 24px);

    color: color($c-black, 100);
  }

  &__description {
    display: block;
    height: 3rem;
    overflow: hidden;
    @include wrap-with-hyphens;
    @include rem(margin, $spacing-xsmall 0);

    @include rem(font-size, 12px);
    line-height: 1rem;
    color: darken(color($c-black, 75), 6.25%); // #5c5c5c
  }

  &__competences {
    $chip-height: 22px;
    $chip-rows-count: 1;

    position: relative;
    @include rem(height, $chip-rows-count * $chip-height);
    @include rem(margin, $spacing-small 0);

    overflow: hidden;

    .mat-chip-list-wrapper {
      justify-content: center;
    }

    .mat-basic-chip {
      @include rem(height, $chip-height);
      @include rem(max-width, 90px);
      @include rem(padding, 0 $spacing-xsmall);

      @include border-radius(4px);

      @include text-truncate;
      @include rem(font-size, 10px);
      @include rem(line-height, $chip-height);

      background: color($c-black, 15);
      color: darken(color($c-black, 75), 1%);

      & + .mat-basic-chip {
        @include rem(margin-left, 7px);
      }
    }
  }

  &__locale {
    display: block;
    height: 1.5rem;
    @include rem(margin, $spacing-small 0);

    @include text-truncate;
    line-height: 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    color: lighten(color($c-black, 75), 6.25%); // #5c5c5c

    dd, dt {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
    }

    dd, dt {
      height: 100%;
    }

    dd {
      @include rem(margin-left, $spacing-xxsmall);

      & + dt {
        @include rem(margin-left, $spacing-small);
      }
    }

    .mat-icon {
      font-size: 1rem;
      line-height: 1.5rem;
      color: color($c-black, 100);
    }
  }

  &__counters {
    display: flex;
    flex: 1;
    flex-flow: row nowrap;
    justify-content: space-between;

    @include rem(margin, 15px 0);
  }
}

.project-counter {
  flex: 0 1 33.33333333%;
  max-width: 33.33333333%;
  @include rem(padding, $spacing-small $spacing-xsmall);

  @include rem(font-size, 14px);
  background-color: #fbfbfb;
  border: 1px solid transparent;

  &__type {
    color: lighten(color($c-black, 50), 1%); // ~ #8f8f8f
  }

  &__count {
    @include rem(margin-top, $spacing-xsmall);
    @include rem(font-size, 20px);
    font-weight: bold;
    color: lighten(color($c-black, 75), 6.25%); // #5c5c5c
  }

  & + & {
    margin-left: 10px;
  }

  &--recommended {
    border-color: color($c-primary, 100);
  }

  &--applied {
    border-width: 2px;
    border-style: dashed;
    border-color: #666666;
  }

  &--shortlisted {
    border-width: 3px;
    border-color: #666666;
  }
}
