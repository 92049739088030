%mat-input-element {
  @include rem(font-size, 14px);
  @include border-radius(3px);
  @include rem(padding, 12px);
  width: 100%;
  margin: 0;
  background-color: color($c-white, 100);
  border: 1px solid color($c-black, 30);
  line-height: 1.2;
}

%mat-input-element-dark {
  @include rem(font-size, 13px);
  @include rem(padding, 5px 0);
  @include rem(padding-top, 11px);
  @include border-radius(0);
  background-color: transparent;
  border: none;
  border-bottom: 1px solid color($c-black, 25);
  color: color($c-white, 100);
  width: 100%;
}

.mat-form {
  .mat-form-field:not(.mat-form-field-should-float) .mat-form-field-label {
    padding: 12px;
    box-sizing: border-box;
  }

  .mat-input-flex.mat-form-field-flex {
    border: 0;
  }

  .mat-form-field-underline, .mat-form-field-ripple {
    height: 0 !important;
    background: none !important;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field {
    margin: 0;
    display: block;
    width: 100%;

    &.ng-dirty.ng-invalid .mat-input-element {
      border-bottom: 1px solid color($c-red, 100);
    }

    &.ng-touched.ng-invalid .mat-input-element {
      border-bottom: 1px solid color($c-red, 100);
    }

    &.ng-dirty.ng-valid .mat-input-element {
      border-bottom: 1px solid color($c-green, 100);
    }

    &.ng-touched.ng-valid .mat-input-element {
      border-bottom: 1px solid color($c-green, 100);
    }

    &.mat-focused {
      .mat-input-placeholder,
      .mat-input-placeholder.mat-form-field-empty {
        color: color($c-black, 75);
      }
    }

    .mat-input-placeholder-wrapper {
      top: -5px;
      left: 12px;
      padding-top: 0;

      .mat-input-placeholder {
        line-height: 1.25;
        display: inline-block !important;
        width: auto !important;
        background-color: color($c-white, 100);
      }
    }

    .mat-input-placeholder {
      @include rem(font-size, 13px);
      color: color($c-black, 75);
      font-weight: normal;
      height: auto;
      overflow: inherit;

      &.mat-form-field-empty {
        color: color($c-secondary, 25);
      }
    }

    .mat-input-element {
      @extend %mat-input-element;
    }

    //.mat-input-infix {
    //  padding-top: 0;
    //  border-top: 0;
    //}

    .mat-input-underline {
      display: none;
    }

    .mat-input-wrapper {
      padding-bottom: 0;
    }

    //.mat-input-infix {
    //  padding-bottom: 0;
    //}

    .mat-placeholder-required {
      display: none;
    }

    &.mat-form-field-should-float {
      .mat-input-placeholder {
        @include rem(font-size, 12px);
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }

  .form-control-error {
    margin-bottom: 0;
    float: none;
  }
}

.mat-form--dark {
  .ui-autocomplete-multiple {
    border-bottom: 1px solid color($c-black, 25);

    input {
      border: none;
    }

    .ui-autocomplete-panel {
      @include persistent-scrollbars;
      background-color: color($c-secondary, 50);

      .ui-autocomplete-list-item {
        span {
          color: color($c-white, 100);
        }

        &:hover, &.ui-state-highlight {
          background-color: color($c-primary, 100);

          span {
            color: color($c-secondary, 100);
          }
        }
      }
    }
  }

  .input-label {
    @include rem(font-size, 10px);
    color: color($c-primary, 100);
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .input-element {
    @extend %mat-input-element-dark;
    @include rem(padding-top, 5px);
    @include placeholder() {
      @include rem(font-size, 13px);
      color: color($c-secondary, 25);
    }

    margin: 0;
  }

  .mat-form-field {
    .mat-input-placeholder-wrapper {
      left: 0;

      .mat-input-placeholder {
        color: color($c-primary, 100);
        background-color: transparent;
      }
    }

    .mat-input-element {
      @extend %mat-input-element-dark;
    }

    .mat-input-placeholder {
      @include rem(font-size, 13px);
      color: color($c-primary, 100);
      font-weight: normal;

      &.mat-form-field-empty {
        color: color($c-secondary, 25);
      }
    }

    &.mat-focused {
      .mat-input-placeholder,
      .mat-input-placeholder.mat-form-field-empty {
        color: color($c-primary, 100);
      }
    }

    &:not(.mat-focused) {
      .mat-input-placeholder.mat-form-field-empty {
        top: 13px;
      }
    }

    &.mat-form-field-should-float {
      .mat-input-placeholder {
        @include rem(font-size, 13px);
      }
    }

    &.tags-field {
      .mat-input-element {
        border: none;
      }

      //.mat-input-infix {
      //border-bottom: 1px solid color($c-black, 25);
      //}
    }
  }

}

.mat-autocomplete-panel {
  @include persistent-scrollbars;

  .mat-option {
    @include rem(font-size, 13px);
    line-height: 30px;
    height: 30px;
  }
}

.mat-autocomplete-panel.dark {
  background-color: color($c-secondary, 50);

  .mat-option {
    color: color($c-white, 100);

    &:hover, &.mat-active {
      color: color($c-secondary, 100);
      background-color: color($c-primary, 100);
    }
  }
}

.mat-input-file-element {
  @extend %mat-input-element;
  cursor: pointer;
  box-sizing: border-box;

  .mat-icon {
    @include rem(font-size, 26px);
    vertical-align: middle;
  }
}

.mat-radio-group {

}

.mat-radio-button.mat-accent {

  .mat-radio-container,
  .mat-radio-inner-circle,
  .mat-radio-outer-circle {
    height: 18px;
    width: 18px;
  }

  .mat-radio-outer-circle {
    border-color: color($c-primary, 100);
  }

  .mat-radio-ripple {
    display: none;
  }

  .mat-radio-label-content {
    @include rem(font-size, 14px);
  }

  + .mat-radio-button {
    @include rem(margin-left, $spacing-xsmall);
  }

  &.mat-radio-checked {
    .mat-radio-inner-circle {
      @include transform(scale(.4));
      background-color: color($c-primary, 100);
    }

    .mat-radio-outer-circle {
      border-color: color($c-primary, 100);
    }
  }
}


.mat-form-input-group {
  display: flex;

  // God damn the person who stylized form controls twice with different styles in _mat-form.scss and _forms.scss
  .form-control {
    @include persistent-scrollbars;
    @include box-shadow(none);
    @include rem(font-size, 13px);
    @include rem(padding, 12px);
    @include transition(border 200ms ease-in-out, box-shadow 200ms ease-in-out);
    @include border-radius(4px);
    border: 1px solid color($c-black, 25);
    width: 100%;
    font-family: $f-secondary;
    font-weight: 400;
    outline: 0;
    line-height: 1.4;
    background: color($c-white, 100);
    height: auto;
  }

  .mat-form-field {
    width: 100%;

    & + .mat-form-field {
      @include rem(margin-left, $spacing-xsmall);
    }
  }
}
