
.header-container {
  display: flex;
  justify-content: center;
  @include rem(padding-bottom, $spacing-medium);
  @include rem(margin-bottom, $spacing-large);
  border-bottom: 1px solid color($c-black, 25);
}

.header-title {
  @include rem(margin-bottom, $spacing-xsmall);
  text-transform: none;
}

// Panels
// ==============================

$panel-margin: $spacing-medium;
$panel-height-collapsed: 160px;
$panel-height-expanded: 560px;


.dashboard-panels {
  display: flex;
  @include flex-grid-row(null, 75rem);
  @include flex-direction(row-reverse);
  @include rem(margin-bottom, $spacing-large);
}

.dashboard-column {
  display: flex;
  flex-flow: column wrap;

  @include flex-grid-column(6, (2 * $spacing-small));
}

.dashboard-panel {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  @include rem(height, $panel-height-collapsed);
  @include rem(margin-left, $panel-margin / 2);
  @include rem(margin-right, $panel-margin / 2);
  overflow: hidden;

  @include box-shadow(0 5px 20px rgba(0, 0, 0, .1));
  background-color: color($c-white, 100);
  color: color($c-black, 85);

  @include transition(height 0.3s ease);
  will-change: height;

  &__container {
    display: flex;
    flex-flow: column wrap;
    height: 100%;
  }

  &__header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    @include rem(padding, $spacing-large $spacing-large);
    border-bottom: 1px solid color($c-black, 25);
  }

  &__body {
    display: block;
    max-height: 0;
    overflow: hidden;
    background-color: color($c-black, 12);
    @include transition(max-height 0.2s ease);
  }

  &__title {
    margin: 0;
    padding: 0.5rem 0;
    @include rem(font-size, 18px);
  }

  &__action {
    display: inline-block;
    width: auto;
    margin-right: 0;

    .mat-icon {
      margin-left: 0;
    }
  }

  &__toggle {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    @include rem(width, 40px);
    @include rem(height, 40px);
    cursor: pointer;
    user-select: none;

    &::before {
      position: absolute;
      content: "";
      @include rem(width, 80px);
      @include rem(height, 40px);

      @include transform(rotate(-45deg) translate(-15px, -5px));
      @include box-shadow(inset 0px 1px 20px 2px color($c-black, 100, 0.1));
      background: color($c-primary, 80);
    }

    .mat-icon {
      position: absolute;
      @include rem(right, 4px);
      @include rem(bottom, 4px);
      font-weight: bold;
      color: color($c-white, 100);
    }
  }

  &__placeholder {
    display: none;

    position: relative;
    top: 50%;
    @include transform(translateY(-50%));
    max-width: 70%;
    margin: 0 auto;

    @include rem(padding, $spacing-small $spacing-medium $spacing-medium);
    @include rem(line-height, 20px);
    @include rem(font-size, 14px);
    text-align: center;
    color: color($c-black, 50);

    .text {
      display: block;
    }

    .btn {
      display: inline-block;
      width: auto;
      @include rem(margin-top, $spacing-large);
    }
  }

  &--empty {
    .dashboard-panel__placeholder {
      display: block;
    }
  }

  & + & {
    @include rem(margin-top, $panel-margin);
  }

  &--expanded {
    @include rem(height, $panel-height-expanded);
    @include transition(height 0.3s ease);

    .dashboard-panel__body {
      display: block;
      flex: 1;
      max-height: 10000px;
      @include transition(max-height 0.2s ease);
    }
  }

  &--expandable {
    .dashboard-panel__header {
      position: relative;
      top: 50%;
      @include transform(translateY(-50%));
      @include transition(transform 0.3s ease 0.15s, top 0.3s ease 0.15s);
      will-change: transform, top;
      backface-visibility: hidden;
      @include rem(padding, $spacing-medium $spacing-large);
      border: none;
    }

    .dashboard-panel__title {
      max-width: 50%;
      @include rem(font-size, 30px);
      font-weight: 500;
    }

    &.dashboard-panel--expanded {
      .dashboard-panel__header {
        top: 0;
        @include transform(translateY(0));
        @include transition(transform 0.15s ease, top 0.15s ease);
      }
    }
  }

  &--quickstart {

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      @include rem(height, $panel-height-collapsed + $spacing-large);
      background: transparent url("/assets/img/b2b/pages/dashboard/guide_background.png") no-repeat 100% -130px;
    }

    img {
      z-index: 2;
      position: relative;
      display: block;
      max-width: 100%;
      height: auto;
      max-height: 100%;
      margin: 0 auto;
      user-select: none;

      @include opacity(0);
      @include transform(translateY(50px));
      @include transition(transform 0.15s ease, opacity 0.15s);
      will-change: transform, opacity;
      backface-visibility: hidden;
    }

    &.dashboard-panel--expanded {
      img {
        @include opacity(1);
        @include transform(translateY(0));
        @include transition(transform 0.3s ease 0.2s, opacity 0.3s ease 0.2s);
      }
    }
  }

  &--freelancers {
    background-color: color($c-secondary, 75);
    color: color($c-white, 100);
  }

  &--missions {
    @include rem(height, $panel-height-expanded - $panel-height-collapsed - $panel-margin);
  }
}


.dashboard-column--affix {
  display: block;
  position: relative;
  width: 100%;

  .dashboard-panel {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;

    width: calc(50% - #{$panel-margin});
  }
}

.dashboard-column--fluid {
  .dashboard-panel {
    flex-grow: 1;
    width: calc(50% - #{$panel-margin});

    &:nth-child(-n + 2) { // only first two panels
      margin-right: calc(50% + #{$panel-margin / 2});
    }

    &:nth-child(1):not(.dashboard-panel--expanded) + .dashboard-panel {
      @include rem(height, $panel-height-expanded - $panel-height-collapsed - $panel-margin);
    }

    &--expanded + & {
      @include rem(margin-right, $panel-margin / 2);
    }
  }
}


@include breakpoint(xlarge down) {

  .dashboard-panels {
    flex-flow: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .dashboard-column {
    flex: auto;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    max-width: none;

    & + & {
      @include rem(margin-bottom, $panel-margin);
    }
  }

  .dashboard-panel {
    width: 100%;
    height: auto;
  }
}

@include breakpoint(large up) {
  .header-container {
    display: flex;
    justify-content: space-between;

    .column:first-child {
      flex-basis: calc(70% - #{$spacing-small});
    }

    .column:nth-child(2) {
      flex-basis: calc(30% - #{$spacing-small});
      text-align: right;

      .btn-new-mission {
        @include rem(margin-top, $spacing-xsmall);
        width: auto;
        display: inline-block;
      }
    }
  }
}

@include breakpoint(small down) {
  .dashboard-panel {
    &__action {
      width: 100%;
    }
  }
}
