.mat-snack-bar-container {
  margin-bottom: 40px !important;
  padding: 24px !important;
  font-family: $f-secondary;
  font-size: 13px;

  &.success {
    background-color: color($c-green, 100);
    color: color($c-white, 100);
  }

  &.error {
    background-color: color($c-red, 100);
    color: color($c-white, 100);
  }

  .mat-simple-snackbar {
    justify-content: center !important;
  }
}
