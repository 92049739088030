.is-freelancer-toggle {
  margin-bottom: 1.5625rem;

  mat-slide-toggle {
    margin-bottom: 0;
  }

  .alert.alert-danger {
    .form-control-error {
      margin-top: 0;
      padding-left: 0;
    }
  }
}
