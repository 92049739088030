.ui-dropdown {
  cursor: pointer;

  .ui-dropdown-panel {
    background-color: color($c-white, 100);
  }

  .ui-dropdown-item {
    @include rem(padding, 5px 10px);
    cursor: pointer;
  }

  .ui-helper-hidden-accessible {
    input {
      display: none;
    }
  }
}
