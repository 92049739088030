input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

textarea {
  resize: none;
}

a.close {
  position: absolute;
  @include rem(right, 5px);
  @include rem(top, 5px);
  @include rem(line-height, 5px);
  z-index: 999;
  cursor: pointer;
}

.form-group {
  @include rem(margin-bottom, $spacing-medium);
  clear: both;

  > label {
    @include rem(font-size, 14px);
    @include rem(margin-bottom, 5px);
    display: inline-block;
  }

  &.absolute-label {
    position: relative;

    label {
      display: none;
    }

    .form-control {
      &:focus {
        @include box-shadow(none);
        border: 1px solid color($c-primary, 100);
      }

      &.ng-dirty, &.ng-touched {
        @include placeholder() {
          color: transparent;
        }

        + label {
          @include rem(font-size, 10px);
          @include rem(padding, 3px 5px 0 5px);
          @include animation(fadeIn 300ms ease-out);
          background-color: color($c-white, 100);
          text-transform: uppercase;
          display: block;
          position: absolute;
          top: -10px;
          left: 10px;
          font-weight: 600;
        }
      }

      &.mark-as-dirty.ng-invalid,
      &.ng-dirty.ng-touched.ng-invalid {
        @include box-shadow(none);
        border: 1px solid color($c-red, 100);
      }

      &.mark-as-dirty.ng-valid,
      &.ng-dirty.ng-touched.ng-valid {
        @include box-shadow(none);
        border: 1px solid color($c-green, 100);
      }
    }
  }
}

.form-view--group {
  @include flex-grid-row;
  margin: 0;
  border-top: 1px solid color($c-black, 30);
  flex-wrap: nowrap;

  > label, > .value {
    @include flex-grid-column;
    @include rem(padding, $spacing-small $spacing-xsmall);
  }

  > label {
    flex-basis: 100px;
    flex-grow: 0;
    flex-shrink: 0;
    text-transform: uppercase;
    padding-left: 0
  }

  //::ng-deep {
  .inplace-form-control--content {
    @include rem(margin-top, 4px);
  }

  .inplace-form-control--form {
    @include rem(margin-top, 4px);
  }

  //}

  > .value {
    max-width: calc(100% - 100px);
  }

  .form-group:last-child {
    margin-bottom: 0;
  }
}

.mkto-wrapper form.mktoForm .mktoFormRow .mktoFormCol .mktoField,
.form-control, .input-as-textarea {
  @include persistent-scrollbars;
  @include box-shadow(none);
  @include rem(font-size, 13px);
  @include rem(padding, 12px);
  @include transition(border 200ms ease-in-out, box-shadow 200ms ease-in-out);
  @include border-radius(4px);
  border: 1px solid color($c-black, 25);
  width: 100%;
  font-family: $f-secondary;
  font-weight: 400;
  outline: 0;
  line-height: 1.4;
  background: color($c-white, 100);
  height: auto;

  &:focus {
    @include box-shadow(0 2px 2px color($c-primary, 100, .7));
    outline: 0;
  }

  &.ng-dirty.ng-touched.ng-invalid {
    @include box-shadow(0 2px 2px color($c-red, 100, .7));
    border-bottom: 1px solid color($c-red, 100);
  }

  &.ng-dirty.ng-touched.ng-valid {
    @include box-shadow(0 2px 2px color($c-green, 100, .7));
    border-bottom: 1px solid color($c-green, 100);
  }

  @include placeholder() {
    @include rem(font-size, 13px);
    font-family: $f-secondary;
    color: color($c-secondary, 25);
  }
}

.form-control-placeholder {
  @extend .form-control;
  display: block;

  &.textarea {
    min-height: 120px;
  }
}

.form-control-text {
  line-height: 0;

  &.pending {
    &::after {
      @include transform(translate(-50%, -50%));
      @include opacity(0.5);
      background-color: transparent;
      background-image: url(/assets/img/loader-bars.svg);
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      height: 30px;
      width: 30px;
    }

    .form-control {
      @include rem(padding-right, 55px);
    }
  }
}

.form-control-search {
  position: relative;

  &::before {
    //@include fa-icon;
    //@include fa-icon;
    position: absolute;
    //content: $fa-var-search;
    color: color($c-secondary, 75);
  }

  .form-control {
    @include rem(padding-right, 35px);
  }

  .fa.fa-times {
    @include rem(right, 15px);
    position: absolute;
    color: color($c-primary, 100);
    cursor: pointer;

    &:hover, &:focus {
      color: color($c-primary, 120);
    }
  }
}

.form-control-error {
  @include rem(font-size, 11px);
  @include rem(margin, 5px 0 12px 0);
  @include animation(fadeIn 0.5s);
  color: color($c-red, 100);
  display: block;
  text-align: left;
  float: left;
  line-height: normal;
}


.mat-form-field-infix {
  width: auto!important;
}

.form-control-select {
  position: relative;

  select {
    @include rem(padding-right, 70px);
    @include rem(height, 44px);
    -webkit-appearance: none;
    -moz-appearance: none;
    line-height: 1.2;
    color: color($c-secondary, 25);

    &::-ms-expand {
      display: none;
    }

    &.ng-dirty, &.ng-touched {
      color: color($c-secondary, 100);
    }

    &.ng-valid.ng-pristine {
      color: color($c-secondary, 100);
    }

    &.ng-invalid {
      color: color($c-secondary, 25);
    }
  }

  mat-select {
    position: relative;
    width: auto;
    display: block;
    max-width: 100%;
    //width: 90%;

    .mat-select-arrow {
      visibility: hidden;
    }

    &::before {
      @include material-icons();
      content: 'keyboard_arrow_down';
      @include border-right-radius(4px);
      pointer-events: none;
      color: color($c-white, 100);
      background-color: color($c-primary, 100);
      display: block;
      position: absolute;
      right: -2px;
      top: -1px;
      height: 43px;
      width: 45px;
      text-align: center;
      line-height: 44px;
    }
  }


  &.ng-invalid::before {
    background-color: color($c-red, 100);
  }

  &.ng-valid::before {
    background-color: color($c-green, 100);
  }

  &.disabled {
    select {
      color: color($c-secondary, 25);
    }

    &::before {
      @include opacity(0.6);
    }
  }
}

.mkto-wrapper form.mktoForm .mktoFormRow .mktoLogicalField.mktoCheckboxList,
.form-control-checkbox {
  @include clearfix();
  @include rem(margin, 0 0 10px);
  text-align: left;

  input[type="checkbox"] {
    display: none;

    & + label {
      @include rem(padding-bottom, 2px);
      position: relative;
      display: block;

      &::after, &::before {
        content: '';
        float: left;
        width: 16px;
        height: 16px;
        display: block;
        position: absolute;
        top: 3px;
        left: 0;
      }

      &::before {
        @include border-radius(4px);
        border: 1px solid color($c-black, 25);
        background: color($c-white, 100);
      }

      &:focus {
        @include box-shadow(0 0 8px color($c-primary, 100, .9));
        outline: 0;
      }
    }

    &:checked + label::after {
      //@include fa-icon;
      @include rem(font-size, 14px);
      font-family: 'Material Icons';
      content: '\e5ca';
      text-align: center;
      color: color($c-primary, 100);
      top: 5px;
      left: 1px;
    }

    &.ng-invalid.ng-dirty.ng-touched + label {
      border-color: color($c-red, 100);

      span {
        color: color($c-red, 100);
      }

      &::before {
        border-color: color($c-red, 100);
      }

      &::after {
        color: color($c-red, 100);
      }

      &:focus {
        @include box-shadow(0 0 8px color($c-red, 100, .9));
      }
    }

    &.ng-valid.ng-dirty.ng-touched[pattern=true] + label {
      border-color: color($c-green, 100);

      span {
        color: color($c-green, 100);
      }

      &::before {
        border-color: color($c-green, 100);
      }

      &::after {
        color: color($c-green, 100);
      }

      &:focus {
        @include box-shadow(0 0 8px color($c-green, 100, .9));
      }
    }

  }

  span {
    @include rem(font-size, 13px);
    @include rem(padding-left, 30px);
    display: block;
    font-weight: 400;
    line-height: 1.7;
  }
}

.input-group {
  display: block;
  width: 100%;
  position: relative;

  .input-group-field, .input-group-button {
    display: block;
    height: auto;
    width: 100%;
  }

  .input-group-field {
    @include rem(margin-bottom, $spacing-medium);
  }

  .input-group-button {
    vertical-align: top;
  }

  .form-control:first-child {
    @include border-radius(4px);
  }

  @include breakpoint(medium up) {
    display: table;
    .input-group-button {
      //min-width: 180px;
    }

    .form-control:first-child {
      @include border-right-radius(0);
      border-right-width: 0 !important;
      margin: 0;
    }

    .btn:last-child {
      @include border-left-radius(0);
      border-left-width: 0 !important;
      margin: 0;
    }

    .form-control-error {
      position: absolute;
    }

    .input-group-field, .input-group-button {
      display: table-cell;
    }
  }

  &.share {
    .input-group-field {
      display: flex;
      border: 1px solid color($c-black, 25);
      border-radius: 4px;

      input.form-control {
        @include rem(padding, 7px 0 7px 7px);
        border: 0;
      }

      .input-group-button {
        @include rem(padding-right, 2px);
        width: auto !important;
        min-width: 60px !important;
        box-shadow: -15px 0px 13px -4px rgba(255, 255, 255, 0.9);
        z-index: 1;

        button.btn {
          &.btn-copy {
            @include rem(padding, 5px 10px);
            @include rem(margin-top, 2px);
            @include rem(border-radius, 4px);
            color: color($c-white, 100);
            text-transform: capitalize;
          }

          &.btn-lock {
            @include rem(padding, 5px 10px);
            @include rem(margin-top, 2px);
            @include rem(border-radius, 4px);
          }
        }
      }
    }
  }

}

.form-actions {
  clear: both;

  .btn {
    margin-left: auto;
    margin-right: auto;
  }
}

.form-control-radio {
  @include clearfix();
  @include rem(margin, 0 0 10px);
  text-align: left;

  &.inline {
    display: inline-block;

    & + .form-control-radio.inline {
      @include rem(padding-left, $spacing-small);
    }
  }

  input[type="radio"] {
    display: none;

    & + label {
      @include wrap-with-hyphens;
      position: relative;
      display: inline-block;
      cursor: pointer;

      &::after, &::before {
        @include border-radius(100%);
        content: '';
        float: left;
        width: 17px;
        height: 17px;
        display: block;
        position: absolute;
        left: 0;
        top: 1px;
      }

      &::before {
        border: 1px solid color($c-black, 25);
        background: color($c-white, 100);
      }

      &::after {
        width: 9px;
        height: 9px;
      }

      &:focus {
        @include box-shadow(0 0 8px color($c-primary, 100, .9));
        outline: 0;
      }
    }

    &:checked + label::after {
      background: color($c-primary, 100);
      left: 5px;
      top: 6px;
    }

  }

  span {
    @include rem(font-size, 13px);
    @include rem(padding-left, 30px);
    display: block;
    font-weight: 400;
    line-height: 1.7;
  }

}

.ui-autocomplete-multiple {
  display: block;
  width: 100%;

  .ui-inputtext {
    display: block;
    padding: 0;
  }

  .ui-autocomplete-token {
    background-color: color($c-secondary, 10);
    @include rem(margin-right, 5px);

    + .ui-autocomplete-input-token {
      @include rem(margin-left, 2px);
      display: inline-block;
    }
  }

  .ui-autocomplete-token-label {
    @include rem(font-size, 12px);
    @include rem(margin, 0 18px 0 0);
    line-height: 1.5;
  }

  .ui-autocomplete-token-icon {
    margin-top: -0.6em;

    &::before {
      @include rem(font-size, 12px);
    }
  }

  .ui-autocomplete-input-token {
    display: block;
    margin-left: 0;
    padding: 0;
  }

  .ui-autocomplete-panel {
    background-color: color($c-white, 100);

    .ui-autocomplete-list-item {
      @include rem(padding, 0 16px);
      margin: 0;

      span {
        @include rem(font-size, 13px);
        line-height: 30px;
        height: 30px;
      }
    }
  }

  .ui-autocomplete-items {
    padding: 0;
  }
}

.input-with-icon {
  position: relative;

  .mat-icon {
    @include rem(font-size, 14px);
    @include rem(left, 15px);
    @include rem(top, 5px);
    color: color($c-black, 90);
    position: absolute;
    z-index: 1;
  }

  .fa-lock {
    @include rem(font-size, 18px);
  }

  input {
    @include rem(padding-left, 40px);
  }
}

textarea.input-as-textarea {
  height: 45px;
  resize: none!important;
  border-bottom: 2px;
}

