
.freelancer-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  border-radius: 3px;
  //@include border-radius(1%);
  //@include rem(padding, 0 0 $spacing-xsmall 0);
  @include rem(margin-bottom, 2 * $spacing-xsmall);
  @include transition(box-shadow .3s ease);
  @include word-wrap(break-word);
  text-align: center;
  background-color: color($c-white, 100);
  cursor: pointer;
  @include box-shadow(1px 1px 10px 0 color($c-black, 100, 0.1));

  &:hover {
    @include box-shadow(1px 4px 10px 4px color($c-black, 100, 0.1));
  }

  &__section {
    margin-top: 0;
    position: relative;

    & + & {
      margin-top: 0.75rem;
    }

    &:last-child {
      margin-top: 0;
    }
  }

  &__section-placeholder {
    .mat-basic-chip {
      opacity: 0.3;
    }
  }

  &__badges {
    display: flex;
    justify-content: space-between;
    //height: 24px;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__badge {
    display: block;
    @include rem(width, 24px);
    @include rem(height, 24px);

    background: transparent none no-repeat scroll 50% 50%;
    @include background-size(contain);

    &--project-attached {
      margin-right: auto;
      background-image: url('/assets/img/icon-files/icon-handshake.png');
    }

    &--assessed {
      background-image: url('/assets/img/icon.png');
      margin-left: auto;
    }
  }

  &__availability, &__status {
    position: absolute;
    left: 10px;
    bottom: 0;
    z-index: 1;
    @include rem(font-size, 12px);
    color: color($c-white, 100);

    .available, .not-available {
      display: block;
      @include rem(padding, 1px 3px);
      border-radius: 2px 2px 0 0;
      @include rem(font-size, 11px);
    }

    .available {
      background-color: color($c-primary, 100);
    }

    .not-available {
      background-color: color($c-black, 50);
    }
  }

  &__status {
    display: block;
    @include rem(padding, 1px 3px);
    border-radius: 2px 2px 0 0;
    @include rem(font-size, 11px);
    font-weight: bold;
  }

  &__thumbnail,
  &__thumbnail-inner {
    display: block;
  }

  &__thumbnail {
    overflow: hidden;
    border-radius: 3px 3px 0 0;
    width: 100%;
    @include rem(height, 160px);
    margin: 0 auto;
    border-bottom: 3px solid color($c-black, 40);
  }

  &__thumbnail-inner {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background: transparent none no-repeat scroll center;
    background-size: cover;
  }

  &__name,
  &__position {
    display: block;
    width: 100%;
    overflow: hidden;

    @include wrap-with-hyphens;
  }

  &__name {
    font-family: $f-primary;
    height: 1rem;
    line-height: 1rem;
    text-transform: uppercase;
    color: color($c-black, 90);
    @include rem(font-size, 14px);
    font-weight: bold;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &__position {
    font-family: $f-primary;
    height: 2rem;
    line-height: 1rem;
    @include rem(font-size, 14px);
    text-transform: lowercase;
    color: color($c-black, 50);
    @include rem(padding, 0 0 10px);
    margin-top: 0;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  &__summary {
    position: relative;
    display: block;
    margin-bottom: 0.75rem;
    @include rem(padding, 0 16px);
    @include rem(font-size, 14px);
    //@include rem(line-height, 22px);
  }

  &__summary-item, &__summary-item-placeholder {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    @include rem(height, 16px);
    @include rem(line-height, 16px);
    @include text-truncate();

    & + & {
      @include rem(margin-top, $spacing-xxsmall);
    }


    dt, dd {
      @include rem(font-size, 14px);
      font-family: $f-primary;
      font-weight: 400;
      margin: 0;
      padding: 0;

      .mat-icon {
        @include rem(font-size, 18px);
      }
    }

    dt {
      @include rem(height, 16px);
      @include rem(width, 16px);
      color: lighten(color($c-black, 50), 1%); // ~ #8f8f8f
    }

    dd {
      width: 100%;
      text-align: left;
      margin-left: $spacing-small;
      color: darken(color($c-black, 75), 6.25%); // #5c5c5c
    }
  }

  &__summary-item-placeholder {
    dt {
      color: color($c-primary, 100, 0.2);
    }

    dd {
      background: color($c-black, 2, 0.08);
    }

    .freelancer-card__assessed-icon {
      opacity: 0.2;
      background: url('/assets/img/icon.png') center no-repeat transparent;
      background-size: 100%;
      width: 16px;
      height: 16px;
      @include rem(margin-left, $spacing-xxsmall);
      display: block;
    }
  }

  &__technologies {
    $chips-top-margin: 16px;
    $chip-height: 22px;
    $chip-rows-gutter: 8px;
    $chip-rows-count: 2;

    border-top: 1px solid color($c-black, 25);
    position: relative;
    @include rem(height, ($chip-rows-count * $chip-height) + (($chip-rows-count - 1) * $chip-rows-gutter) + $chips-top-margin);
    @include rem(padding-top, $chips-top-margin);

    @include rem(margin, 0 16px 16px);
    overflow: hidden;

    .mat-chip-list-wrapper {
      justify-content: center;
    }

    .mat-basic-chip {
      @include rem(height, $chip-height);
      @include rem(max-width, 90px);
      @include rem(padding, 0 $spacing-xsmall);
      @include rem(margin-bottom, $chip-rows-gutter);

      @include border-radius(4px);

      @include text-truncate;
      @include rem(font-size, 10px);
      @include rem(line-height, $chip-height);

      background: color($c-black, 15);
      color: darken(color($c-black, 75), 1%);

      & + .mat-basic-chip {
        @include rem(margin-left, 7px);
      }
    }
  }

}


.freelancer-card--available {

  &.freelancer-card {
    padding: 0;
  }

  .freelancer-card__summary-item {
    dt {
      .mat-icon {
        color: color($c-primary, 100);
      }
    }
  }

  .freelancer-card__thumbnail {
    padding: 0;
    border-color: color($c-primary, 100);
  }

}

.freelancer-card--is-assessed {
  margin-top: -2px;
  border: 2px solid color($c-primary, 100);

  .freelancer-card__assessed-icon {
    background: url('/assets/img/icon.png') center no-repeat transparent;
    background-size: 100%;
    width: 16px;
    height: 16px;
    @include rem(margin-left, $spacing-xxsmall);
    display: block;
  }
}

.freelancer-card--promotion {
  height: calc(100% - 1.25rem); // calculate normal height = stretched card height - bottom margin
  justify-content: space-around;
  text-align: left;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1;
  background-color: color($c-primary, 100);

  & > .freelancer-card__section {
    padding: 1rem 1.5rem;
  }

  em {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: 6px;
    font-size: 1.4rem;
    font-weight: 600;
    font-style: normal;
    background-color: color($c-black, 76);
    color: color($c-white, 100);
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  p, em {
    font-weight: 600;
  }
}
