.page-block {
  @include rem(padding, $spacing-large);
  display: table;
  height: inherit;
  width: 100%;
  position: relative;

  .page-block-overlay {
    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: color($c-secondary, 100, 0.82);
    }

    &::after {
      content: '';
      background: -webkit-linear-gradient(top, color($c-black, 100, 0.55),
        transparent 30%,
        transparent 70%,
        color($c-black, 100, 0.55));
      background: linear-gradient(180deg, color($c-black, 100, 0.55) 0,
        transparent 30%,
        transparent 70%,
        color($c-black, 100, 0.55));
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .page-block-container {
    display: table-cell;
    vertical-align: middle;
  }

  .page-block-content {
    position: relative;
    z-index: 3;
  }

  @include breakpoint(medium up) {
    @include rem(padding, $spacing-xlarge $spacing-large);
  }

  @include breakpoint(xxlarge up) {
    @include rem(padding, $spacing-xxlarge $spacing-large);
  }

}

.page-block-heading {
  @include rem(margin-bottom, $spacing-large);
  text-align: center;
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;

  &::before {
    @include rem(margin-bottom, $spacing-large);
    margin-right: auto;
    margin-left: auto;
    display: block;
    content: '';
    width: 60px;
    height: 6px;
    background-color: color($c-primary, 100);
  }

  h1 {
    margin: 0 auto;
    line-height: 1.4;
    color: color($c-secondary, 100);
    font-family: $f-primary;
    text-transform: uppercase;
    width: 100%;
    max-width: 630px;
    text-align: center;
  }

  @include breakpoint(large up) {
    h1 {
      max-width: 730px;
    }
  }

  @include breakpoint(xxlarge up) {
    @include rem(margin-bottom, $spacing-xxlarge);

    &::before {
      @include rem(margin-bottom, $spacing-large);
      width: 90px;
      height: 8px;
    }

  }

}
