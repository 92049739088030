.sv-card-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  //.sv-card {
  //  @include flex-grid-column(4, 0);
  //}
}

.sv-card {
  &:hover {
    .sv-card-content {
      @include box-shadow(1px 4px 10px 4px color($c-black, 100, 0.1));
      z-index: 1;
    }
  }

  .sv-card-content {
    @include box-shadow(1px 1px 10px 0 color($c-black, 100, 0.1));
    @include transition(box-shadow 0.2s ease-out);
    @include rem(margin, $spacing-small);
    @include rem(padding, $spacing-small);
    background-color: color($c-white, 100);
    position: relative;
    z-index: 0;


  }
}

@include breakpoint(medium down) {
  .sv-card-container {
    .sv-card {
      @include flex-grid-column(6, 0);
    }
  }
}

@include breakpoint(small down) {
  .sv-card-container {
    .sv-card {
      @include flex-grid-column(12, 0);
    }
  }
}

@include breakpoint(medium up) {
  .sv-card-container {
    .sv-card {
      .sv-card-content {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 0;
        @include rem(margin-bottom, $spacing-xsmall);

        .sv-cell {
          &.col-6 {
            @include flex-grid-column(2, 0);
          }

          &.col-4 {
            @include flex-grid-column(3, 0);
          }

          &.col-3 {
            @include flex-grid-column(4, 0);
          }

          &.col-2 {
            @include flex-grid-column(6, 0);
          }

          &.col-9 {
            @include flex-grid-column(9, 0);
          }

          &.col-4-offset-1 {
            @include flex-grid-column(5, 0);
          }
        }
      }
    }
  }
}
