/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $f-primary;
  font-weight: 700;
}

h1 {
}

h2 {
}

h3 {
}

h4 {
}

h5 {
}

h6 {
}

p, div, span, a {
  font-family: $f-secondary;
  font-weight: 400;
}

small {
}

mark {
}

code {
}

kbd {
}

pre {
}

blockquote {
  p {
  }
}
