form.custom-form {
  .section {
    @include rem(padding-bottom, $spacing-small);

    &.header {
      h1 {
        @include rem(font-size, 40px);
      }

      h2 {
        @include rem(font-size, 20px);
      }

    }

    span.form-control-error {
      margin-bottom: 0;
    }

    .form-control-checkbox label span {
      @include rem(font-size, 16px);
    }

    div.error {
      @include rem(height, 25px);
      @include rem(margin-top, -12px);
      @include rem(margin-bottom, 10px);
    }

    .mat-input-file-element.error {
      @include box-shadow(0 2px 2px color($c-red, 100, .7));
      border-bottom: 1px solid color($c-red, 100);
    }

    .freelance-terms-errors {
      padding-bottom: $spacing-medium;
    }

    .form-buttons {
      .btn.btn-hallow2.btn-hallow2-red {
        background-color: #cf4647;
        width: auto;
      }

      button {
        display: inline-block;
      }
    }

    + .section {
      border-top: 1px solid color($c-black, 50);
      @include rem(padding-top, $spacing-medium);
    }

    h1 {
      @include rem(margin-bottom, $spacing-medium);
      text-transform: capitalize;

      span {
        @include rem(font-size, 16px);
        color: color($c-black, 50);
        font-style: italic;
        text-transform: lowercase;
      }
    }

    .next-steps {
      @include rem(margin-bottom, $spacing-small);
      @include rem(margin-top, -35px);
      white-space: pre-line;
    }

    .btn.save {
      border-radius: 0;
      width: auto;
      margin: 0;
    }

    .resume-list {
      @include rem(font-size, 14px);

      ul {
        @include rem(padding-left, $spacing-small);
        margin-top: 0;
        list-style-type: none;
      }

      .mat-icon {
        cursor: pointer;
        vertical-align: bottom;
        @include rem(font-size, 16px);
      }

      i.attach {
        color: color($c-black, 40);
      }

      i.remove {
        color: color($c-red, 100);
      }
    }
  }

  .form-control-select.icon,
  .form-control-text.icon {
    position: relative;

    select,
    input {
      @include rem(padding-left, 30px);
    }

    .mat-icon {
      position: absolute;
      top: calc(50% - 10px);
      @include rem(left, 9px);
      @include rem(font-size, 18px);
    }

  }

  div.label {
    @include rem(margin-bottom, $spacing-xsmall);
  }

  .row {
    display: flex;
    flex-direction: column;

    .short-column {
      width: 100%;
    }

    .column {
      position: relative;
      width: 100%;

      &.inline {
        .form-control-text,
        .form-control-select {
          @include rem(margin-bottom, $spacing-small);
          width: 100%;
        }
      }
    }

    .form-group {
      @include rem(margin-bottom, $spacing-small);
    }
  }

  @include breakpoint(medium up) {
    .row {
      display: flex;
      flex-direction: row;

      .short-column {
        width: 50%;
      }

      .column {

        div.error {
          display: none;
        }

        width: 100%;

        + .column.inline {
          margin-left: 0;
        }

        &.inline {
          display: flex;
          flex-direction: row;
          margin-bottom: 0;

          .form-control-text,
          .form-control-select {
            @include rem(margin-left, $spacing-small);
            width: 50%;
          }
        }

        + .column {
          @include rem(margin-left, $spacing-small);
        }
      }
    }
  }

  mat-checkbox.mat-checkbox {
    label.mat-checkbox-layout {
      white-space: normal !important;
      vertical-align: top;
      display: inline;

      span.mat-checkbox-label {
        padding-left: 0;
        display: inline;
      }
    }
  }

  .alert-danger.alert-danger-form {
    margin-bottom: 30px;
  }
}

.material-placeholder {
  color: color($c-black, 55);
  position: relative;
  z-index: 1000;
  &.new-design {
    color: color($c-black, 100);
  }
}

.project-form__group {
  .mat-checkbox-disabled {
    span {
      color: color($c-black, 40);
    }
  }
}

.pretty-dialog {
  .mat-dialog-container {
    @include background-size(cover);
    @include border-radius(0);
    width: 100%;
    background-image: url(/assets/img/modal-bg.jpg);
    background-color: color($c-secondary, 10);
    background-position: center;
    background-repeat: repeat;
    position: relative;
    margin: 0;
  }
}

//.mat-chip-list-wrapper {
//  margin: 0!important;
//}
