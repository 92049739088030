.sv-pagination {
  @include rem(padding, $spacing-small 0);
  display: block;
  text-align: center;

  a {
    cursor: pointer;
  }

  .pagination-numbers, .pagination-previous, .pagination-next {
    display: inline-block;
  }

  .disabled {
    opacity: 0.5;
  }

  .pagination-previous a, .pagination-next a {
    line-height: 38px;
    min-width: 30px;
    min-height: 38px;

    .mat-icon {
      @include rem(font-size, 30px);
      line-height: 38px;
      vertical-align: middle;
    }
  }

  .pagination-numbers {
    a, div {
      @include rem(font-size, 18px);
      @include rem(margin, 0 $spacing-xsmall);
      display: inline-block;
      color: color($c-primary, 100);
      line-height: 38px;
      min-width: 30px;
      min-height: 38px;
      vertical-align: middle;
    }

    div {
      border: 1px solid color($c-primary, 100);
    }
  }
}
