@import "../node_modules/foundation-sites/scss/foundation";

@import "skillvalue-material-core";

@include foundation-normalize;
@include foundation-form-prepostfix;

//$fa-font-path: '~font-awesome/fonts';
//@import '~font-awesome/scss/font-awesome';

@import 'styles/variables';

@import 'styles/functions';

@import 'styles/generic-mixins';

@import 'styles/scaffolding';

@import 'styles/typography';

@import 'styles/forms';

@import 'styles/buttons';

@import 'styles/animations';

@import 'styles/material-skillvalue';

@import 'styles/components/index';

.material {
  @import "~@ng-select/ng-select/themes/material.theme";
}
.default {
  @import "~@ng-select/ng-select/themes/default.theme";
}

//
//// theming
//@import '~@angular/material/theming';
//
//@include mat-core();
//
///* ======== Angular material custom themes ======== */
//$my-custom-primary: mat-palette($c-primary);
//$my-custom-accent: mat-palette(color($c-primary, 80), 100, 500, A100);
//$my-custom-warn: mat-palette(color($c-primary, 125));
//
//$my-custom-theme: mat-light-theme($c-primary, color($c-primary, 130), $c-red);
//
//@include angular-material-theme($my-custom-theme);
//
//$fa-font-path: "~font-awesome/fonts";
//

// cross browser fix
mat-icon {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  //font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
