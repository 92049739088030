@import "src/styles/generic-mixins";
@import "src/styles/variables";
@import '~foundation-sites/scss/foundation';

.campaign-wizard {
  @include border-radius(6px);
  @include rem(font-size, 14px);
  @include transform(width ease-out 300ms);
  background-color: color($c-secondary, 100);
  color: color($c-secondary, 15);
  -webkit-box-shadow: 7px 10px 24px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 7px 10px 24px -11px rgba(0, 0, 0, 0.75);
  box-shadow: 7px 10px 24px -11px rgba(0, 0, 0, 0.75);

  .steps-content {
    @include rem(min-height, 120px);
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .actions {
    @include rem(padding, $spacing-large);
    @include border-radius(5px);
    @include rem(bottom, -140px);
    text-align: center;
    width: 100%;
    height: 100%;
    position: absolute;
    background: color($c-secondary, 10);
    border: 1px solid color($c-secondary, 15);
    z-index: 99999;
    transition: bottom .2s ease-in-out;

    .btn {
      margin: 0;

      + .btn {
        @include rem(margin-left, $spacing-xsmall);
      }
    }

    &.on {
      bottom: 0;
    }
  }
}


.campaign-wizard--header {
  @include border-left-radius(5px);
  @include rem(padding, $spacing-large);
  background-color: #172523;
  width: 25%;

}

.campaign-wizard--header-title {
  text-transform: uppercase;
  color: color($c-white, 100);
  font-weight: 600;
}

.campaign-wizard--header-description {
  @include rem(font-size, 13px);

  ::ng-deep {
    .mat-form .mat-form-field {
      width: 100%;
      margin: 0;

      .mat-input-infix {
        padding: 0 5px;

        .mat-input-element {
          @include rem(font-size, 13px);
          color: color($c-secondary, 100);
          padding: 4px 5px !important;
        }
      }
    }
  }

  .wrapper-name {
    border: 1px solid transparent;
    cursor: text;

    .mat-icon {
      @include rem(font-size, 16px);
      vertical-align: bottom;
      display: inline;
      color: color($c-secondary, 15);
    }

    .name {
      @include rem(padding, 3px 5px);
      max-width: 100%;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: top;

      + span {
        @include rem(padding, 3px 5px);
        display: inline-block;
      }
    }

    &:hover {
      border: 1px solid color($c-secondary, 10);
      border-radius: 3px;

      .mat-icon {
        color: color($c-secondary, 100);
        vertical-align: top;
      }

      .name {
        + span {
          float: right;
          background: color($c-secondary, 10);
          cursor: pointer;
        }
      }
    }
  }

  .form-control-error {
    @include rem(max-width, 300px);
    @include rem(text-indent, 5px);
    display: block;
    word-wrap: break-word;
    white-space: normal;
    margin: 0;
  }
}

.campaign-wizard--step {
  @include rem(padding, $spacing-medium);
  position: relative;
  flex-grow: 1;
  cursor: pointer;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: color($c-primary, 60);
  position: relative;

  + .campaign-wizard--step {
    border-left: 1px solid color($c-primary, 120);

    &::before {
      @include rem(font-size, 35px);
      @include rem(width, 35px);
      @include rem(height, 35px);
      color: color($c-primary, 100);
      display: block;
      position: absolute;
      border-radius: 50%;
      background-color: white;
      left: -18px;
      z-index: 100;
      top: calc(50% - 20px);
      content: '\E315';
      font-family: 'Material Icons';
    }
  }

  &.is-active, &.was-active {
    background: color($c-primary, 110);

    .campaign-wizard--step-title span,
    .campaign-wizard--step-description {
      color: color($c-secondary, 100);
      opacity: 1;
    }

  }

  &.step3 {
    @include border-right-radius(5px);
  }

}

.campaign-wizard--step-indicator {
  @include border-radius(100%);
  @include rem(margin-right, $spacing-small);
  @include rem(padding, 10px);
  @include rem(font-size, 16px);
  float: left;
  border: 2px solid color($c-secondary, 50);
  line-height: 13px;
  position: relative;
  height: 32px;
  width: 32px;
  top: 1px;

  span.value {
    @include absolute-center;
    font-family: $f-primary;
    font-weight: 600;
  }

  span.icon {
    @include absolute-center;
    @include rem(font-size, 18px);
    font-weight: 600;
    display: none;
  }
}

.campaign-wizard--step-title span {
  @include rem(font-size, 16px);
  font-family: $f-primary;
  font-weight: 600;
  color: color($c-secondary, 100);
  opacity: 0.4;
}

.campaign-wizard--step-description {
  @include rem(font-size, 12px);
  @include rem(margin-top, $spacing-xsmall);
  color: color($c-secondary, 100);
  font-style: italic;
  opacity: 0.4;
  text-align: center;
  white-space: normal !important;
}

.campaign-wizard--header-title {
  @include rem(padding-left, 5px);
}

.campaign-wizard--header-title,
.campaign-wizard--header-description,
.campaign-wizard--step-title span,
.campaign-wizard--step-description {
  @include text-truncate;
}
