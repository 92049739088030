.mat-expansion-panel {
  @include box-shadow(none !important);
  border-bottom: 1px solid color($c-black, 15);

  .mat-expansion-panel-body {
    @include rem(padding, $spacing-medium);
    @include rem(padding-top, $spacing-xsmall);
    background-color: color($c-black, 10);
    margin: 0;

    .form-group:last-of-type {
      margin-bottom: 0;
    }
  }

  .mat-expansion-panel-header-title {
    @include rem(font-size, 14px);
    text-transform: uppercase;
    font-weight: bold;
  }

  .mat-expansion-panel-header-description {
    @include rem(font-size, 12px);
    @include rem(margin-right, $spacing-medium);
    font-style: italic;
    color: color($c-black, 30);
    float: right;
    flex-grow: 0;

    .separator {
      @include rem(margin, 0 $spacing-xsmall);
      font-style: normal;
    }
  }

  .mat-expansion-panel-header {
    cursor: pointer;

    .mat-expansion-indicator {
      @include rem(margin-top, 8px);

      &::after {
        color: color($c-primary, 100);
      }
    }

    &.mat-expanded, &.mat-expanded:focus, &.mat-expanded:hover {
      background-color: color($c-black, 10);
    }

    &:not(.mat-expanded) {
      .mat-expansion-indicator {
        @include rem(margin-top, -8px);
      }

      &:hover {
        background-color: color($c-white, 100) !important;
      }
    }
  }
}
