/* Fonts */
$f-primary: 'Quicksand', serif;
$f-secondary: 'Open Sans', serif;

/* Spacing */
$spacing-xxsmall: 5px;
$spacing-xsmall: 10px;
$spacing-small: 15px;
$spacing-medium: 25px;
$spacing-large: 35px;
$spacing-xlarge: 60px;
$spacing-xxlarge: 80px;

/* Additional breakpoints */
$breakpoint-small-phone: 320px;

$color-orange: (
  10: #fef5f2,
  25: #fcded2,
  50: #f8bda6,
  60: #f59c79,
  75: #f27b4d,
  80: #ee581e,
  90: #e24c11,
  100: #c9440f,
  105: #b03b0d,
  107: #97330b,
  110: #7e2a09
);

$color-blue: (
  10: #f0f7ff,
  25: #cfe4fe,
  50: #9ecafc,
  60: #6eaffb,
  75: #3d95f9,
  80: #0b7af8,
  90: #076ee3,
  100: #0662ca,
  105: #0555b1,
  107: #054997,
  110: #043d7e
);

// Palette
$c-primary: (
  10: #fef5f2,
  25: #fcded2,
  50: #f8bda6,
  60: #f59c79,
  75: #f27b4d,
  80: #f37745,
  90: #ef6431,
  100: #ee581e,
  110: #e24c11,
  120: #c9440f,
  130: #b03b0d,
  140: #97330b,
  150: #7e2a09,
);

$c-secondary: (
  4: #fcfcfc,
  5: #f2f4f6,
  10: #e7e8e8,
  15: #acb4b2,
  25: #9aa09f,
  20: #999999,
  50: #79817f,
  55: #777777,
  60: #5f6866,
  65: #d8d8d8,
  75: #4d5755,
  80: #424242,
  85: #333333,
  90: #282828,
  95: #1a2a26,
  96: #33403d,
  100: #13201d,
  110: #131716,
  120: #4c5755,
);

$c-white: (
  100: #ffffff,
);

$c-black: (
  2: #abafae,
  3: #fbfbfa,
  5: #fcfcfb,
  7: #f1f1f1,
  8: #f8f8f6,
  10: #f6f6f6,
  11: #f7f7f7,
  12: #f9f9f9,
  15: #e9e9e9,
  16: #e0e0e0,
  17: #e8e8e7,
  25: #cecece,
  26: #cccccc,
  30: #c0c0c0,
  35: #dbdddd,
  40: #b8bcbb,
  41: #b0b3b1,
  45: #a2a2a2,
  50: #888f8d,
  55: #808080,
  65: #666666,
  67: #5d6664,
  70: #4d5755,
  75: #4c4c4c,
  76: #14201e,
  80: #13201d,
  85: #12201d,
  90: #0A0E0D,
  100: #000000,
);

$c-blue: (
  25: #B6E4F6,
  50: #7fc5ce,
  75: #419bcf,
  100: #008C9E,
  125: #006977,
  150: #0a79f8,
  200: #061369,
);

$c-red: (
  25: #edb9ba,
  50: #e7a2a3,
  75: #f27d27,
  80: #ff6163,
  100: #CF4647,
  125: #9c3535,
  150: #d43030,
  175: #ee581e,
  180: #f30c0c,
);

$c-green: (
  10: #1bb88b,
  20: #a1e302,
  100: #88C100,
  110: #78b91f,
  125: #659100,
  150: #20AD78
);

$c-socials: (
  facebook: #3b5998,
  google: #d34836,
  linkedin: #0083be,
  github: #343434,
  twitter: #00c3fe,
  skype: #01a3df
);

// Returns a color from a palette/color-map.
// For example: color($app-blue, 500) will return #106cc8
//
// $color-map: a map of {key: color}.
// $hue-key: key used to lookup the color in $colorMap. Defaults to 'default'
//     If $hue-key is a number between 0 and 1, it will be treated as $opacity.
// $opacity: the opacity to apply to the color.
@function color($color-map, $hue-key: default, $opacity: 1) {
  // If hueKey is a number between zero and one, then it actually contains an
  // opacity value, so recall this function with the default hue and that given opacity.
  @if type-of($hue-key) == number and $hue-key >= 0 and $hue-key <= 1 {
    @return color($color-map, default, $hue-key)
  }

  $color: map-get($color-map, $hue-key);
  $opacity: if(opacity($color) < 1, opacity($color), $opacity);

  @return rgba($color, $opacity);
}

.c-secondary {
  color: color($c-secondary, 20);
}
