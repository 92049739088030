@import "src/styles/_generic-mixins.scss";
@import "src/styles/_variables.scss";
@import '~foundation-sites/scss/foundation';
::ng-deep {
  .my-profile {
    .search-input-container {
      input {
        width: 47%!important;
      }
    }
  }
}
:host {
  ::ng-deep {

    .autocomplete-chip-list {
      width: 100%;

      .mat-chip-list-wrapper {
        flex-flow: column;
        align-items: self-start;
        margin: 0;

        .search-input-container {
          width: 100%;
          @include rem(margin-bottom, $spacing-xxsmall);
        }

        .chips-container {
          //display: flex;
          //flex-flow: row;
        }
      }

      .mat-input-placeholder-wrapper {
        position: absolute;
        box-sizing: content-box;
        width: 100%;
        height: 100%;
        overflow: visible;
        pointer-events: none;
        top: -5px;
        left: 12px;
        padding-top: 0;

        .mat-input-placeholder, .mat-form-field-placeholder {
          text-transform: uppercase;
          @include rem(font-size, 16px);
          box-sizing: content-box;
          display: block;
          font-family: "Open Sans", serif;
          height: 12px;
          left: 0px;
          pointer-events: none;
          position: absolute;
          text-align: left;
          background-color: rgb(255, 255, 255);
          color: rgb(154, 160, 159);
          cursor: default;
          font-size: 12px;
          font-stretch: 100%;
          font-style: normal;
          font-variant-caps: normal;
          font-variant-east-asian: normal;
          font-variant-ligatures: normal;
          font-variant-numeric: normal;
          font-weight: 600;
          line-height: 15px;
          overflow-x: visible;
          overflow-y: visible;
          padding-top: 0px;
          text-overflow: ellipsis;
          top: 15.375px;
          transform: matrix3d(0.75, 0, 0, 0, 0, 0.75, 0, 0, 0, 0.15375, 1, -0.01, 0, -15.3748, 0.001, 0.99999);
          transform-origin: 0px 0px;
          transition-delay: 0s, 0s, 0s;
          transition-duration: 0.4s, 0.4s, 0.4s;
          transition-property: transform, color, width;
          transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1), cubic-bezier(0.25, 0.8, 0.25, 1), cubic-bezier(0.25, 0.8, 0.25, 1);
          white-space: nowrap;
        }
      }

      .stack-item {
        &:hover {
          h3 {
            .btn-hollow {
              display: block;
            }
          }
        }

        h3 {
          position: relative;

          .btn-hollow {
            display: none;
            position: absolute;
            top: 10px;
            right: 0;
            width: auto;
            cursor: pointer;
            @include rem(font-size, 12px);

            i {
              @include rem(font-size, 12px);
              position: relative;
              top: -2px;
            }
          }
        }

        ul {
          margin-right: 100px;

          li {
            i.material-icons {
              vertical-align: -2px;
            }
          }
        }
      }

      i.material-icons {
        cursor: pointer;
      }

      .mat-chip {
        //background-color: color($c-primary, 100);
        margin-top: 3px;
        border-radius: 3px;
        line-height: 22px;
        font-size: 0.75rem;
        width: max-content;

        .mat-icon {
          font-size: 18px;
        }
      }

      .mat-chip {
        margin-left: 0 !important;
        margin-right: 8px !important;
      }

      .mat-chip-trailing-icon {
        margin-top: 2px;
      }
    }
  }
}

