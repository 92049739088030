%corner-gradient {
  background: -moz-linear-gradient(-45deg, color($c-black, 10) 0%, #ffffff 20%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, color($c-black, 10) 0%, #ffffff 20%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, color($c-black, 10) 0%, #ffffff 20%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.filter-card {
  @include box-shadow(0 5px 20px rgba(0, 0, 0, .07));
  @include rem(padding, $spacing-medium);
  background-color: color($c-white, 100);
  position: relative;

  .filter-item {
    label {
      cursor: pointer;
    }

    & + .filter-item {
      @include rem(margin-top, $spacing-xsmall);
    }
  }

  .form-control-checkbox {
    margin-bottom: 0;

    input[type="checkbox"]:checked {
      + label > span {
        color: color($c-primary, 100);
      }
    }

    &.collapsed {
      max-height: 410px;
      overflow: hidden;
    }
  }

  .btn-show-more {
    @include rem(font-size, 13px);
    @include rem(padding, 4px 0);
    @include rem(margin-top, $spacing-xsmall);
    background: none;
    border: 0;
    color: color($c-primary, 100);
    cursor: pointer;

    .mat-icon {
      @include rem(font-size, 18px);
      @include rem(margin-left, 10px);
      vertical-align: middle;
    }
  }

  .btn-remove-all {
    @extend %corner-gradient;
    @include rem(font-size, 11px);
    @include rem(padding, 10px 10px 5px);
    border: none;
    color: color($c-secondary, 15);
    position: absolute;
    top: 0;
    right: 0;
    border-left: 1px solid color($c-black, 15);
    cursor: pointer;

    .mat-icon {
      @include rem(font-size, 14px);
      @include rem(margin-left, 7px);
      color: color($c-red, 100);
      vertical-align: middle;
    }

    &:hover {
      color: color($c-red, 100);
      font-style: italic;
    }
  }

  + .filter-card {
    @include rem(margin-top, $spacing-small);
  }

  &.blank {
    @include box-shadow(none);
    background: none;
    padding: 0;
  }
}

.filter-card--title {
  @include rem(margin-bottom, $spacing-medium);
  @include rem(font-size, 18px);
  font-family: $f-primary;
  font-weight: 600;
}
