$maxLevel: 5;
$barHeightStart: 100%/$maxLevel;
$barMargin: 8%;

.level {

  i.bar {
    width: (100% - ($barMargin * $maxLevel))/$maxLevel;
    background-color: color($c-primary, 100);
    display: inline-block;

    & + i.bar {
      margin-left: $barMargin;
    }

    &.empty {
      background-color: color($c-secondary, 75);
    }

    @for $index from 1 through $maxLevel {
      &:nth-child(#{$index}) {
        height: ($barHeightStart * $index);
      }
    }
  }

}
